// MANAGED BY App.js
import { useEffect, useState } from 'react'
import { Form } from '../preorder_cars'
import { useLocation } from 'react-router-dom'

const StockImageSetForm = () => {
  let [data, setData] = useState()
  let [loading, setLoading] = useState(false)

  const location = useLocation()
  const { manufacturer_id, family_id, year, badge, simple_colour, bodyconfiguration } =
    location?.state || {}

  useEffect(() => {
    if (data) {
      return
    }
    setLoading(true)
    fetch(`/stock_image_sets/new.json`)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  })

  return (
    <div className="container py-3">
      <h5>New Stock Image Set</h5>
      {data && data?.colours && (
        <div className="box p-3">
          <form action="/stock_image_sets" method="post">
            <Form
              manufacturer_id={manufacturer_id}
              family_id={family_id}
              defaultYear={year}
              defaultBadge={badge}
              defaultColour={simple_colour}
              defaultBodyconfiguration={bodyconfiguration}
              recordType="stock_image_set"
              showBadge={true}
              colours={data?.colours?.map((c) => {
                return { value: c, label: c }
              })}
            />
          </form>
        </div>
      )}
    </div>
  )
}

export default StockImageSetForm
