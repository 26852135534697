import { useState } from 'react'
import { Sidebar } from 'primereact/sidebar'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Accordion, AccordionTab } from 'primereact/accordion'
import moment from 'moment'

const sidebarStyle = () => ({
  height: '100%',
  width: '100%',
  maxHeight: '100vh',
  maxWidth: '1000px',
  borderRadius: 0,
  backgroundColor: '#fff',
})

const DeliveryCreatedAt = ({ created_at }) => {
  return moment(created_at).fromNow()
}

const ArrivedAt = ({ arrived_at }) => {
  return moment(arrived_at).fromNow()
}

const User = ({ user, emails, loadingEmails }) => {
  return (
    <div>
      <h5>{user.position}</h5>
      <Accordion activeIndex={0}>
        <AccordionTab header="Reviews">
          <div className="box">
            <DataTable value={user.reviews}>
              <Column field="customer_name" header="Customer" />
              <Column field="text" header="Content" />
              <Column field="rating" header="Rating" />
              <Column field="created_at_time_ago_in_words" header="Created at" />
            </DataTable>
          </div>
        </AccordionTab>
        <AccordionTab header="Deliveries">
          <div className="box">
            <DataTable value={user.deliveries}>
              <Column field="contact.full_name" header="Name" />
              <Column field="created_at" header="Created At" body={DeliveryCreatedAt} />
            </DataTable>
          </div>
        </AccordionTab>
        <AccordionTab header="Recent Emails Received">
          <p>{user.email} has received the below emails:</p>
          <div className="box">
            <DataTable value={emails} loading={loadingEmails}>
              <Column field="subject" header="Subject" />
              <Column field="status" header="Status" />
              <Column field="arrived_at" header="Arrived At" body={ArrivedAt} />
            </DataTable>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
  )
}

const Show = ({ visible, setVisible, userId, userName }) => {
  let [loading, setLoading] = useState(true)
  let [user, setUser] = useState({})
  let [emails, setEmails] = useState([])
  let [loadingEmails, setLoadingEmails] = useState(true)
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (!visible) return

    fetch(`/dealerships/${dealershipSlug}/users/${userId}/show_react.json`)
      .then((res) => res.json())
      .then((res) => {
        setUser(res)
        setLoading(false)
      })
  }, [visible])

  useEffect(() => {
    if (!visible) return

    setLoadingEmails(true)
    fetch(`/dealerships/${dealershipSlug}/users/${userId}/mj_emails.json`)
      .then((res) => res.json())
      .then((res) => {
        setEmails(res.emails)
        setLoadingEmails(false)
      })
  }, [user])

  return (
    <div>
      <Sidebar
        header={userName}
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        style={{ ...sidebarStyle() }}
      >
        <div className="p-3">
          {loading ? (
            <div>Loading...</div>
          ) : (
            <User user={user} emails={emails} loadingEmails={loadingEmails} />
          )}
        </div>
      </Sidebar>
    </div>
  )
}

export default Show
