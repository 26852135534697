import { useState, useEffect } from 'react'
import { standardHeaders } from '../entries/utils'

export const CommentTextarea = ({ comments, setComments, loading }) => {
  const minWordCount = 100
  const wordCount = comments?.split(/\s+/).filter((word) => word.length > 0).length

  return (
    <>
      <textarea
        className={'form-control' + (loading ? ' disabled' : '')}
        placeholder={loading ? 'Loading...' : 'Dealer Comments'}
        rows={5}
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        disabled={loading}
      />
      {!loading && wordCount < minWordCount && (
        <div className="text-danger mt-1 small">
          We recommend at least {minWordCount} words (currently {wordCount} words).
        </div>
      )}
    </>
  )
}

const EditingComments = ({ car, setCar, setEditingComments }) => {
  let [comments, setComments] = useState('')
  let [loading, setLoading] = useState(false)
  let [saving, setSaving] = useState(false)

  const onSave = () => {
    setSaving(true)
    fetch(`/cars/${car.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        car: {
          dealercomments: comments,
        },
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setSaving(false)
        setCar({ ...car, dealercomments: comments })
        setEditingComments(false)
      })
      .catch((error) => console.log('Error:', error))
  }

  useEffect(() => {
    setLoading(true)
    fetch(`/cars/${car.id}/show_react.json`)
      .then((response) => response.json())
      .then((data) => {
        setComments(data.car.dealercomments)
        setLoading(false)
      })
  }, [])

  return (
    <div className="p-3">
      <div className="mb-2 text-secondary">{car.name}</div>
      <div className="d-flex">
        <div className="mr-2" style={{ width: 120 }}>
          <img
            src={car.primary_image_url}
            className="rounded border"
            loading="lazy"
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <div className="w-100">
          <CommentTextarea comments={comments} setComments={setComments} loading={loading} />
          <div className="mt-2">
            <div
              className={'btn btn-sm btn-outline-secondary btn-block' + (saving ? ' disabled' : '')}
              onClick={onSave}
            >
              {saving ? (
                <>
                  <i className="fas fa-spinner fa-spin mr-2" />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditingComments
