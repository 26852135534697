import { useState, useRef, useContext } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { isTouchDevice } from '../../utils'
import { CarLoanContext } from '../../../contexts'

interface CustomerAgreementProps {
  agreementAgreedAt?: Date
  sendConfirmationMessage: any
  verifyConfirmationCode: any
  recordSignature: any
}
export type AgreementMethod = 'email' | 'sms' | 'signature'

const CustomerAgreement = ({
  verifyConfirmationCode,
  sendConfirmationMessage,
  recordSignature,
}: CustomerAgreementProps) => {
  const { carLoan }: any = useContext(CarLoanContext)
  if (!carLoan) return null
  const { contact, agreement_agreed_at, agreement_signature_url } = carLoan
  const { email, phone } = contact

  const [selectedMethod, setSelectedMethod] = useState<AgreementMethod | null>(null)
  const [readyForConfirmationCode, setReadyForConfirmationCode] = useState<boolean>(false)
  const [signature, setSignature] = useState<string | null>(null)
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null)

  const sigCanvasRef = useRef<any>(null)

  // TODO: Enable SMS verification
  const smsVerificationEnabled = false
  // TODO: Enable Signature on mobile devices
  const signatureEnabled = true

  const handleSignatureSave = async () => {
    if (sigCanvasRef.current) {
      const signaturePng = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      setSignature(signaturePng)
      await recordSignature(signaturePng)
    }
  }

  const resetSelection = () => {
    setSelectedMethod(null)
    setSignature(null)
  }

  const sendMessage = async () => {
    const result = await sendConfirmationMessage(selectedMethod)
    if (result) {
      setReadyForConfirmationCode(true)
    }
  }

  const verifyCode = async () => {
    const result = await verifyConfirmationCode(confirmationCode)

    if (result) {
      setReadyForConfirmationCode(false)
    }
  }

  const hasValidContactDetails = email || phone

  return (
    <div className="card mb-4">
      <div className="card-header">Customer Agreement</div>
      <div className="card-body">
        {agreement_agreed_at != null ? (
          <>
            <p>
              <i className="fa fa-check text-success text-lg"></i> You have already agreed to the
              terms &amp; conditions on {new Date(agreement_agreed_at).toLocaleString()}
            </p>
            {signature && (
              <div>
                <img src={signature} alt="Signature" />
              </div>
            )}
            {agreement_signature_url && (
              <div>
                <img src={agreement_signature_url} alt="Signature" />
              </div>
            )}
            {
              // delete button
              (signature || agreement_signature_url) && (
                <button
                  type="button"
                  className="btn btn-danger m-2"
                  onClick={() => {
                    setSignature(null)
                    recordSignature(null)
                  }}
                >
                  <i className="fa fa-trash"></i> Remove Signature
                </button>
              )
            }
          </>
        ) : (
          <>
            <div>
              <p className="font-weight-light small">
                By completing the following, you acknowledge that you have read and agree to the
                agreement displayed above.
              </p>
              {!selectedMethod && (
                <div className="d-flex flex-column flex-md-row">
                  {email && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2 mr-md-2"
                      onClick={() => setSelectedMethod('email')}
                    >
                      <i className="fa fa-envelope"></i> Email Agreement
                    </button>
                  )}
                  {phone && smsVerificationEnabled && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2 mr-md-2"
                      onClick={() => setSelectedMethod('sms')}
                    >
                      <i className="fa fa-sms"></i> SMS Agreement
                    </button>
                  )}
                  {isTouchDevice() && signatureEnabled && (
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={() => setSelectedMethod('signature')}
                    >
                      <i className="fa fa-signature"></i> Signature
                    </button>
                  )}
                </div>
              )}
              {(selectedMethod === 'email' || selectedMethod === 'sms') &&
                hasValidContactDetails &&
                !readyForConfirmationCode && (
                  <div className="d-flex">
                    <button type="button" className="btn btn-primary mt-2" onClick={sendMessage}>
                      <i className="fa fa-paper-plane"></i> Send Agreement
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary btn-danger mt-2 ml-2"
                      onClick={resetSelection}
                      aria-label="Close"
                    >
                      <i className="fas fa-times"></i> Cancel
                    </button>
                  </div>
                )}

              {readyForConfirmationCode && (
                <div>
                  <p className="font-weight-light small">
                    Please enter the confirmation code sent to you.
                  </p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Confirmation Code"
                    style={{ maxWidth: '200px' }}
                    value={confirmationCode || ''}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                    maxLength={6}
                  />
                  <button type="button" className="btn btn-info mt-2" onClick={verifyCode}>
                    Confirm
                  </button>
                </div>
              )}
              {selectedMethod === 'signature' && (
                <div>
                  <SignatureCanvas
                    ref={sigCanvasRef}
                    penColor="black"
                    canvasProps={{
                      width: 400,
                      height: 200,
                      className: 'sigCanvas',
                      style: { border: '1px solid black', display: 'block' },
                    }}
                  />
                  <button
                    type="button"
                    className="btn btn-success mt-2"
                    onClick={handleSignatureSave}
                  >
                    Save Signature
                  </button>
                </div>
              )}
              {signature && (
                <div>
                  <img src={signature} alt="Signature" />
                </div>
              )}
              {!hasValidContactDetails && (
                <p className="alert alert-warning">
                  Please update your profile with a valid email or phone number to complete the
                  agreement.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default CustomerAgreement
