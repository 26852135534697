import { useState, useEffect, useContext } from 'react'
import { DealershipContext } from '../../../contexts'
import ContactSelector from '../../../shared/ContactSelector'
import VehicleSelector from '../../../shared/VehicleSelector'

export const NewCarLoan = ({ onCreateCarLoan }) => {
  const [selectedCar, setSelectedCar] = useState(null)
  const [selectedContact, setSelectedContact] = useState(null)

  const { dealership }: any = useContext(DealershipContext)

  useEffect(() => {
    if (selectedCar && selectedContact) {
      onCreateCarLoan({
        carId: selectedCar.value,
        contactId: selectedContact.value,
      })
    }
  }, [selectedCar, selectedContact])

  return (
    <div>
      <div className="row">
        <ContactSelector
          dealership={dealership}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
        />
        <VehicleSelector
          dealership={dealership}
          selectedCar={selectedCar}
          setSelectedCar={setSelectedCar}
        />
      </div>
    </div>
  )
}
