// MANAGED BY App.js
import { Configure, InstantSearch, Pagination, SearchBox } from 'react-instantsearch'

import * as Routes from '../../routes'
import { useFetchDealership } from '../dataHooks'
import { meilisearchClient } from '../entries/utils'
import CustomHits from './customHits'

const Col = ({ children }) => <div className="col-md-6 col-lg-4 col-xl-3 mb-2">{children}</div>

const Header = ({ dealershipId }) => (
  <div className="clearfix mb-2">
    <h3>
      Listing contacts
      <div className="float-right">
        <a
          href={`/dealerships/${dealershipId}/contacts.csv`}
          className="btn btn-outline-primary mr-2"
        >
          <i className="fa fa-download" /> Export to CSV
        </a>
        <a
          href={Routes.upload_dealership_contacts_path(dealershipId)}
          className="btn btn-outline-secondary mr-2"
        >
          <i className="fa fa-upload" /> Upload CSV
        </a>
        <a
          href={Routes.new_dealership_contact_path(dealershipId)}
          className="btn btn-outline-success"
        >
          <i className="fa fa-plus mr-1" />
          Contact
        </a>
      </div>
    </h3>
  </div>
)

const App = () => {
  const { dealership } = useFetchDealership()
  const searchClient = meilisearchClient()
  let filters = `dealership_id=${dealership?.id}`

  return (
    <div className="p-3 w-100">
      <Header dealershipId={dealership?.id || 0} />
      <InstantSearch
        indexName={`Contact_${process.env.RAILS_ENV}:created_at:desc`}
        searchClient={searchClient}
        routing={true}
      >
        <Configure hitsPerPage={40} filters={filters} />
        <div className="box p-3">
          <div className="row">
            <Col>
              <SearchBox className="mb-0" placeholder="Search contacts" />
            </Col>
          </div>
        </div>
        <CustomHits />
        <div className="py-3">
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  )
}

export default App
