import { Tooltip } from 'react-tooltip'
import TwgScore from './CarScores/TwgScore'
import { carGradeBadgeClass } from './CarScores/utils'

const DefaultScore = ({ hit }) => (
  <>
    <div
      className={`badge ${carGradeBadgeClass(hit.autograb_data.car_grade)}`}
      id={`car-grade-${hit.id}`}
    >
      {hit.autograb_data?.car_grade} ({hit.autograb_data.car_score})
    </div>
    <Tooltip anchorSelect={`#car-grade-${hit.id}`} place="bottom-start" style={{ zIndex: 10000 }}>
      <b>Stats</b>
      <div className="small">
        <div>Average days to sell: {hit.autograb_data?.average_days_to_sell}</div>
        <div>Days Supply: {hit.autograb_data?.market_days_supply}</div>
        <div>State sample size: {hit.autograb_data?.state_sample_size}</div>
        <div>
          Price Rank (AU): {hit.autograb_data?.price_rank}/{hit.autograb_data?.sample_size}
        </div>
      </div>

      <div className="mt-2">
        <b>Car Grading</b>

        <div className="small">
          <div>
            <div className={`badge ${carGradeBadgeClass('A')}`}>A</div> = 24-30
          </div>
          <div>
            <div className={`badge ${carGradeBadgeClass('B')}`}>B</div> = 19-24
          </div>
          <div>
            <div className={`badge ${carGradeBadgeClass('C')}`}>C</div> = 15-19
          </div>
          <div>
            <div className={`badge ${carGradeBadgeClass('D')}`}>D</div> = 10-15
          </div>
          <div>
            <div className={`badge ${carGradeBadgeClass('E')}`}>E</div> = 7-10
          </div>
          <div>
            <div className={`badge ${carGradeBadgeClass('F')}`}>F</div> = 0-6
          </div>
        </div>
      </div>
    </Tooltip>
  </>
)

const CarScore = ({ hit }) => {
  // TODO: need a better way to do this based on dealership group
  if (['tony-white-coffs-harbour', 'local-motor-group'].includes(hit.dealership_slug)) {
    return <TwgScore hit={hit} />
  }

  return <DefaultScore hit={hit} />
}

export default CarScore
