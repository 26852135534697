import { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import { InputSwitch } from 'primereact/inputswitch'
import DateTimePicker from '../shared/DateTimePicker'
import { CurrentUserContext, LeadClusterContext } from '../contexts'
import { useFetchDealership } from '../dataHooks'

const AppointmentForm = ({ appointment, onSave }) => {
  const currentUser = useContext(CurrentUserContext)
  const { leadCluster } = useContext(LeadClusterContext)
  const { cars } = leadCluster
  const { dealership } = useFetchDealership()

  const [startsAt, setStartsAt] = useState(appointment?.starts_at || null)
  const [lengthInMinutes, setLengthInMinutes] = useState(appointment?.length_in_minutes || 15)
  const [note, setNote] = useState(appointment?.note || '')
  let [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(appointment?.status || 'pending')
  const [assignedUser, setAssignedUser] = useState({
    label: currentUser?.name,
    value: currentUser?.id,
  })
  const [notifyAssignee, setNotifyAssignee] = useState(false)
  const [notifyContact, setNotifyContact] = useState(false)
  const [purpose, setPurpose] = useState('Other')
  const [selectedCar, setSelectedCar] = useState(
    cars.map((car) => ({ label: car.car?.name, value: car.car?.id }))[0]
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    // If we're creating the appointment, we just need to notify the onSave function that we want a test drive with the selected Car
    // created for it.
    // However if we're updating an existing appointment, we shouldn't be able to alter the purpose of the appointment, or the car.
    setLoading(true)
    await onSave({
      id: appointment?.id,
      startsAt,
      lengthInMinutes,
      note,
      status,
      purpose,
      carForTestDrive: purpose === 'Test Drive' ? selectedCar : null,
      user: { id: assignedUser?.value, name: assignedUser?.label },
      notifyAssignee,
      notifyContact,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (appointment) {
      const localDate = new Date(appointment.starts_at)
      // Get the local time offset in minutes and convert it to milliseconds
      const localTimeOffset = localDate.getTimezoneOffset() * 60000

      // Adjust localDate by the local time offset to get the correct local time
      const adjustedDate = new Date(localDate.getTime() - localTimeOffset)
      setStartsAt(adjustedDate)
      setLengthInMinutes(appointment?.length_in_minutes)
      setNote(appointment.note)
      setStatus(appointment.status)
      setPurpose(appointment.purpose)
      setAssignedUser({ label: appointment.user?.name, value: appointment.user?.id })
    }
  }, [appointment])

  const onAssignedUserChange = (user) => {
    setAssignedUser(user)
  }

  const handleDateChange = (date) => {
    setStartsAt(date[0])
  }

  const handleCarChange = (car) => {
    setSelectedCar(car)
  }

  return (
    <form onSubmit={handleSubmit}>
      {(currentUser?.admin || currentUser?.dealerships?.[dealership?.id]?.can_manage_leads) && (
        <div className="form-group">
          <label htmlFor="user">Assigned to</label>
          <Select
            options={dealership?.available_users}
            name="user"
            value={assignedUser}
            placeholder="Assign User"
            onChange={onAssignedUserChange}
            className={'user-select'}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="startsAt">Starts At</label>
        <DateTimePicker id="startsAt" value={startsAt} onChange={handleDateChange} />
        {/* TODO: finish implementing this as a replacement for the DateTimePicker. Needs to display and disable intervals for unavailable times
        <WeekCalendar
          startTime={moment({ h: 9, m: 0 })}
          endTime={moment({ h: 17, m: 0 })}
          useModal={false}
          firstDay={moment().startOf('isoWeek')}
          numberOfDays={6}
          scaleFormat={'hh:mm p'}
        /> */}
      </div>
      <div className="form-group">
        <label htmlFor="lengthInMinutes">Length (in minutes)</label>
        <input
          type="number"
          className="form-control"
          id="lengthInMinutes"
          value={lengthInMinutes}
          onChange={(e) => setLengthInMinutes(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="purpose">Purpose</label>
        {appointment?.id ? (
          <>
            <input type="text" className="form-control" id="purpose" value={purpose} readOnly />
            {selectedCar && purpose === 'TestDrive' && <p>Test Drive Car: {selectedCar.label}</p>}
          </>
        ) : (
          <select
            className="form-control"
            id="purpose"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          >
            {cars.length > 0 && <option value="Test Drive">Test Drive</option>}
            <option value="Delivery">Delivery</option>
            <option value="Other">Other</option>
          </select>
        )}
      </div>

      {cars?.length > 0 && purpose === 'Test Drive' && !appointment?.id && (
        <div className="form-group">
          <label htmlFor="car">Car for Test Drive</label>
          <Select
            options={cars.map((car) => ({ label: car.car?.name, value: car.car?.id }))}
            name="car"
            value={selectedCar}
            placeholder="Select Car"
            onChange={handleCarChange}
          />
        </div>
      )}

      {appointment?.id && (
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            className="form-control"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="note">Note</label>
        <textarea
          className="form-control"
          id="note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </div>
      {assignedUser?.value !== currentUser?.id && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label htmlFor="notifyAssignee" style={{ marginRight: '10px' }}>
            Notify Assignee
          </label>
          <InputSwitch
            id="notifyAssignee"
            checked={notifyAssignee}
            onChange={(e) => setNotifyAssignee(e.value)}
          />
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label htmlFor="notifyContact" style={{ marginRight: '10px' }}>
          Notify Contact
        </label>
        <InputSwitch
          id="notifyContact"
          checked={notifyContact}
          onChange={(e) => setNotifyContact(e.value)}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        {loading ? 'Saving...' : 'Save'}
      </button>
    </form>
  )
}

export default AppointmentForm
