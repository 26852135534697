import SideMenu from './SideMenu'

const Layout = ({ children }) => (
  <div className="sidemenu-wrapper w-100">
    <div className="d-flex">
      <SideMenu />
      <div className="main w-100">{children}</div>
    </div>
  </div>
)

export default Layout
