import { useState, useContext } from 'react'
import { updateLeadAPI } from './utils'
import { LeadClusterContext, CurrentUserContext } from '../contexts'

const RequiresApproval = () => {
  let [loading, setLoading] = useState(false)
  let { leadCluster, setLeadCluster } = useContext(LeadClusterContext)
  let currentUser = useContext(CurrentUserContext)

  const toggle = async (approved) => {
    setLoading(true)
    let data = {
      lead_cluster: {
        approved: approved,
      },
    }

    await updateLeadAPI(leadCluster.id, data)
      .then((res) => res.json())
      .then((res) => {
        setLeadCluster({
          ...leadCluster,
          lead_state: res.lead_state,
          requires_approval: res.requires_approval,
          approver: res.approver,
        })
      })
    setLoading(false)
  }

  const Loading = () => (
    <div>
      <i className="fa fa-spinner fa-spin"></i>
    </div>
  )

  if (!leadCluster?.requires_approval) {
    return null
  }

  let manager =
    currentUser?.dealerships && currentUser?.dealerships[leadCluster?.dealership_id]?.manager

  return (
    <div>
      {manager ? (
        <button
          className="btn btn-outline-success btn-sm btn-block mt-2"
          onClick={() => toggle(true)}
          disabled={!manager}
          id="approval-btn"
        >
          {loading ? (
            <Loading />
          ) : (
            <span>
              <i className="fa fa-check mr-1"></i>
              Approve
            </span>
          )}
        </button>
      ) : (
        <div className="small text-center text-secondary mt-1">
          <i className="fa fa-lock mr-1"></i>
          Manager Approval Required
        </div>
      )}
    </div>
  )
}

export default RequiresApproval
