import { useEffect, useState } from 'react'
import { useFormState } from 'react-hook-form'

/**
 * Placeholder for -
 * https://tanstack.com/query/latest/docs/framework/react/guides/queries
 * We really should use this package if we want to enhance producivity and
 * improce the user experience of these clientside fetches.
 *
 */
export function useQuery<T>({
  query,
  queryKey,
}: {
  query: (key: string) => Promise<T>
  queryKey: string
}) {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  async function fetchData() {
    setLoading(true)
    if (queryKey) {
      try {
        const result = await query(queryKey)
        setData(result)
      } catch (error) {
        setError(error)
      }
    }
  }

  const revalidate = async () => {
    fetchData().then(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!error) {
      setLoading(true)
      fetchData().then(() => {
        setLoading(false)
      })
    }
  }, [query, queryKey])

  return { data, error, loading, revalidate }
}

/** Basic fetch util, should be replaced by a global util */
export const fetchOnClient = async (path: string) => {
  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  const res = await fetch(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    credentials: 'same-origin',
  })
  return await res.json()
}

/** Show loading state in a form component */
export const PendingText = ({ text = 'Save Changes' }) => {
  const { isSubmitting } = useFormState()
  if (isSubmitting) {
    return <span>Loading...</span>
  }
  return <span>{text}</span>
}
