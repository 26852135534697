import { Checkbox } from 'primereact/checkbox'
import { Provider, Root, Trigger, Content } from '@radix-ui/react-tooltip'
import { TooltipContentStyles } from '../../AnalyticsModules/components/ModuleWrapperComponent'
import { useEffect, useState } from 'react'
import { useConfig } from '../../../contexts/hooks'

const FixedMode = () => {
  const { config, handleUpdateConfig } = useConfig()
  const [checked, setChecked] = useState(config?.fixedMode || false)

  const onCheckedChange = () => {
    setChecked(!checked)
    handleUpdateConfig({ fixedMode: !checked })
  }

  useEffect(() => {
    setChecked(config?.fixedMode || false)
  }, [config])

  return (
    <>
      <h5 className="mr-3 mb-0 col-12 mt-3">
        Toggle fixed mode:
        <Provider delayDuration={50}>
          <Root>
            <Trigger asChild>
              <i className="fas fa-info-circle ml-2" style={{ cursor: 'pointer' }}></i>
            </Trigger>
            <Content style={TooltipContentStyles} sideOffset={5}>
              Enabling this will lock the dashboard in place, preventing any changes from being
              made.
            </Content>
          </Root>
        </Provider>
      </h5>
      <div className="col-12 mt-1">
        <Checkbox
          name="Fixed mode"
          value="Fixed mode"
          onChange={() => onCheckedChange()}
          checked={checked}
        />
      </div>
    </>
  )
}

export default FixedMode
