export const NextButton = ({ onClick, disabled }) => {
  let className = disabled ? 'btn btn-secondary mt-2 disabled' : 'btn btn-primary mt-2'

  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      Next
      <i className="fa fa-arrow-right ml-2" />
    </button>
  )
}
