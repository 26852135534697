import { useState, useContext } from 'react'
import { Panel } from 'primereact/panel'
import { Dialog } from 'primereact/dialog'
import moment from 'moment'
import DealerEmail from '../leads/DealerEmail'
import Email from '../leads/Email'
import Apicall from '../leads/Apicall'
import CarsAlsoViewed from '../leads/CarsAlsoViewed'
import { LeadClusterContext } from '../contexts'
import { standardHeaders } from '../entries/utils'

const ResendButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToThirdParties = () => {
    setLoading(true)
    fetch(`/dealerships/${leadCluster.dealership_id}/leads/${lead_id}/resend_to_third_parties`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToThirdParties}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to third party
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to third parties'}
        </>
      )}
    </div>
  )
}

const ResendLeadButton = ({ lead_id }) => {
  let { leadCluster } = useContext(LeadClusterContext)
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const resendToCustomer = () => {
    setLoading(true)
    fetch(`/dealerships/${leadCluster.dealership_id}/leads/${lead_id}/resend_customer_email`, {
      method: 'POST',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        setSent(true)
      })
  }

  return (
    <div
      onClick={resendToCustomer}
      className={'btn btn-outline-secondary btn-block mb-3' + (sent ? ' disabled' : '')}
      disabled={sent || loading}
    >
      {sent ? (
        <div className="text-success">
          <i className="fa fa-check mr-2"></i>
          Resent to Customer
        </div>
      ) : (
        <>
          <i className="fa fa-recycle mr-2"></i>
          {loading ? 'Loading...' : 'Resend to Customer'}
        </>
      )}
    </div>
  )
}

const Emails = ({ lead }) => {
  let [visible, setVisible] = useState(false)
  const { emails, dealer_emails, apicalls } = lead

  return (
    <>
      <div className="btn btn-outline-secondary btn-sm ml-2" onClick={() => setVisible(true)}>
        <i className="fa fa-envelope"></i>
        <span className="ml-2">{emails.length + dealer_emails.length + apicalls.length}</span>
      </div>
      <Dialog
        header="Emails/API calls"
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        {emails?.map((email) => (
          <Email email={email} key={email.id} />
        ))}
        {apicalls?.map((apicall) => (
          <Apicall apicall={apicall} key={apicall.id} />
        ))}
        {dealer_emails?.map((dealerEmail) => (
          <DealerEmail dealerEmail={dealerEmail} key={dealerEmail.id} />
        ))}
        <div className="px-3 mt-2">
          <ResendButton lead_id={lead.id} />
          <ResendLeadButton lead_id={lead.id} />
        </div>
      </Dialog>
    </>
  )
}

const LeadContainer = ({ lead, position }) => {
  let { leadCluster } = useContext(LeadClusterContext)

  const Header = () => (
    <div>
      Lead {position + 1}: {lead.category}
      <small className="text-secondary">
        <span> - {moment(lead.created_at).fromNow()}</span>
        {lead.source && <span> from {lead.source}</span>}
      </small>
    </div>
  )

  const Footer = () => (
    <div className="d-flex">
      <div>
        <a
          href={`/dealerships/${leadCluster.dealership_id}/leads/${lead.id}/edit`}
          className="btn btn-outline-secondary btn-sm"
        >
          <i className="fa fa-edit mr-2"></i>
          Edit
        </a>
        <Emails lead={lead} />
      </div>
      <div className="ml-auto text-secondary">{moment(lead.created_at).format('h:mm a')}</div>
    </div>
  )

  return (
    <Panel header={Header} footer={Footer} toggleable collapsed={position > 2} className="mb-3">
      {lead.car && <p>Lead submitted on a {lead.car.name}</p>}
      <div style={{ overflowX: 'scroll' }} dangerouslySetInnerHTML={{ __html: lead.leadDetails }} />
      {lead.carsAlsoViewed?.length > 0 && <CarsAlsoViewed lead={lead} />}
    </Panel>
  )
}

export default LeadContainer
