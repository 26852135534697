import { csrfToken } from '../entries/utils'

type LoginFormProps = {
  ssoEnabled: boolean
  identityProvidersJson?: string
  isMobileClient: boolean
}

const LoginForm: React.FC<LoginFormProps> = ({
  ssoEnabled,
  identityProvidersJson,
  isMobileClient,
}) => {
  let identityProviders = []

  if (isMobileClient) {
    identityProviders = JSON.parse(identityProvidersJson)
  }

  return (
    <div className="card-body">
      <form action="/users/sign_in" method="post">
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <div className="form-group">
          <label className="form-control-label email required" htmlFor="user_email">
            Email <abbr title="required">*</abbr>
          </label>
          <input
            type="email"
            name="user[email]"
            autoFocus
            placeholder="Email"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label className="form-control-label password required" htmlFor="user_password">
            Password <abbr title="required">*</abbr>
          </label>
          <input
            type="password"
            name="user[password]"
            placeholder="Password"
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-secondary">
          Log in
        </button>
      </form>
      <div className="mt-2">
        <div>
          <a href="/users/password/new">Forgot your password?</a>
        </div>
        <div>
          <a href="/users/unlock/new">Didn't receive unlock instructions?</a>
        </div>
        {ssoEnabled && ( // SSO is always false for the mobile app
          <div>
            <a rel="nofollow" data-method="post" href="/users/auth/saml">
              Sign in with SSO
            </a>
          </div>
        )}

        {isMobileClient && ( // Give the users a dropdown menu to select the SSO provider
          <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Sign in with SSO
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {identityProviders.map((identityProvider) => (
                <a
                  key={identityProvider.name}
                  className="dropdown-item"
                  rel="nofollow"
                  data-method="post"
                  href={`/users/auth/saml?identity_provider_id=${identityProvider.id}`}
                >
                  {identityProvider.name}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginForm
