import React from 'react'

const modalOverlayStyle = (show) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  transition: 'opacity 0.3s ease-in-out, visibility 0.3s',
  opacity: show ? 1 : 0,
  visibility: show ? 'visible' : 'hidden',
  zIndex: 9999,
})

const modalDialogStyle = (show) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  marginBottom: 0,
  marginLeft: 0,
  minWidth: '100%',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  backgroundColor: '#fff',
  transition: 'transform .3s ease-in-out',
  transform: show ? 'translateY(0%)' : 'translateY(100%)',
})

const modalHeaderBodyStyle = {
  padding: '1rem',
}

const modalHeaderStyle = {
  borderBottom: '1px solid #dee2e6',
}

const closeStyle = {
  background: 'none',
  border: 'none',
}

const Modal = ({ title, show, closeClickHandler, children }) => {
  const modalContentRef = React.useRef()

  const handleClick = (e) => {
    // Check if the click occurred outside the modal content
    if (!modalContentRef.current.contains(e.target)) {
      closeClickHandler()
    }
  }

  return (
    <div role="dialog" style={modalOverlayStyle(show)} onClick={handleClick}>
      <div
        ref={modalContentRef}
        className="modal-dialog"
        role="document"
        style={modalDialogStyle(show)}
      >
        <div className="modal-content">
          <div className="modal-header" style={{ ...modalHeaderBodyStyle, ...modalHeaderStyle }}>
            <h5 className="modal-title">{title}</h5>
            <button type="button" style={closeStyle} onClick={closeClickHandler}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body" style={modalHeaderBodyStyle}>
            {children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeClickHandler}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
