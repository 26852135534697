import { Controller } from 'react-hook-form'
import { Dropdown } from 'primereact/dropdown'
import { useContext } from 'react'

import { CarLoanContext } from '../../../contexts'

interface AgreementProps {
  control: any
}

interface formatAgreementProps {
  agreement: string
  dealershipName: string
}

const formatAgreement = ({ agreement, dealershipName }: formatAgreementProps) => {
  return agreement?.replace(/\[dealership_name\]/g, dealershipName)
}

const Agreement = ({ control }: AgreementProps) => {
  const { carLoan }: any = useContext(CarLoanContext)

  if (!carLoan) return null
  const { agreement, dealership, insurance_excess_max } = carLoan

  // prepare options that start from $0 and increment by $500 up to the max insurance excess
  const insuranceExcessOptions = Array.from(
    { length: insurance_excess_max / 500 },
    (_, i) => i + 1
  ).map((i) => ({ label: `$${i * 500}`, value: i * 500 }))
  return (
    <div className="card mb-4">
      <div className="card-header">Agreement</div>
      <div className="card-body">
        <div style={{ maxHeight: '160px', overflowY: 'auto' }}>
          <div
            className="agreement-container pl-2"
            dangerouslySetInnerHTML={{
              __html: formatAgreement({ agreement, dealershipName: dealership?.name }),
            }}
          ></div>
        </div>
      </div>
      <div className="card-footer">
        <label className="mr-2">Insurance Excess</label>
        <Controller
          name="insuranceExcess"
          control={control}
          render={({ field }) => (
            <Dropdown
              {...field}
              options={insuranceExcessOptions}
              placeholder="-- Select an amount --"
            />
          )}
        />
      </div>
    </div>
  )
}

export default Agreement
