import { useRef, useState, useEffect } from 'react'
import { Input, Select, Checkbox } from '../../entries/FormElements'
import { Stepper } from 'primereact/stepper'
import { StepperPanel } from 'primereact/stepperpanel'
import { NextButton } from './utils'
import { useParams } from 'react-router-dom'
import {
  financeMethodCodes,
  employmentOptions,
  residentialOptions,
  useOptions,
  garageOptions,
  annualDistanceOptions,
  genderCodeOptions,
  booleanOptions,
} from './SelectOptions'
import GooglePlacesAutoComplete from '../../entries/AddressAutocomplete'
import { standardHeaders } from '../../entries/utils'

const InsuredPerson = ({ person, data, setData }) => {
  const onChange = (e) => {
    let newPeople = [...data.people]
    let index = newPeople.findIndex((p) => p === person)
    newPeople[index] = { ...newPeople[index], [e.target.name]: e.target.value }
    setData({ people: newPeople })
  }

  const removePerson = () => {
    let newPeople = data.people.filter((p) => p !== person)
    setData({ people: newPeople })
  }

  return (
    <div className="border rounded p-3 mb-2 bg-white">
      <div className="d-flex mb-3">
        <small className="text-secondary">{person.type}</small>
        {person.type !== 'PRIMARY' && (
          <div className="ml-auto">
            <div className="btn btn-outline-danger btn-sm" onClick={removePerson}>
              <i className="fa fa-times" />
            </div>
          </div>
        )}
      </div>
      <Input
        name="first_name"
        required={true}
        value={person.first_name}
        label="First Name"
        onChange={onChange}
      />
      <Input
        name="last_name"
        required={true}
        value={person.last_name}
        label="Last Name"
        onChange={onChange}
      />
      <Input name="email" required={true} value={person.email} label="Email" onChange={onChange} />
      <Input
        name="phone_number"
        required={true}
        value={person.phone_number}
        label="Phone Number"
        onChange={onChange}
      />
    </div>
  )
}

const Wrapper = () => {
  let { leadClusterId, dealershipSlug } = useParams()

  let [contact, setContact] = useState(false)

  useEffect(() => {
    fetch(`/dealerships/${dealershipSlug}/lead_clusters/${leadClusterId}/insurance.json`)
      .then((res) => res.json())
      .then((res) => {
        setContact(res)
      })
  }, [])

  if (!contact) {
    return <p>Loading...</p>
  }

  return <Form contact={contact} />
}

const Form = ({ contact }) => {
  const stepperRef = useRef(null)
  let { dealershipSlug } = useParams()

  let defaultData = {
    people: [
      {
        type: 'PRIMARY',
        title: '',
        email: contact.email,
        first_name: contact.first_name,
        last_name: contact.last_name,
        phone_number: contact.phone_number,
      },
    ],
  }

  let [data, setData] = useState(defaultData)
  let [consent, setConsent] = useState(false)
  let [employmentStatus, setEmploymentStatus] = useState()
  let [residentalStatus, setResidentialOptions] = useState()
  let [financeMethodCode, setFinanceMethodCode] = useState()
  let [loadingSubmit, setLoadingSubmit] = useState(false)
  let [birthday, setBirthday] = useState()
  let [vehicleUseCode, setVehicleUseCode] = useState()
  let [underTwentyFiveFlag, setUnderTwentyFiveFlag] = useState()
  let [garageCode, setGarageCode] = useState()
  let [annualDistanceCode, setAnnualDistanceCode] = useState()
  let [addressDescription, setAddressDescription] = useState()
  let [placeId, setPlaceId] = useState()
  let [genderCode, setGenderCode] = useState()
  let [ownOtherVehicleFlag, setOwnOtherVehicleFlag] = useState()
  let [insurancePolicy, setInsurancePolicy] = useState(false)

  let valid = () => {
    return genderCode && consent && birthday
  }

  let disabled = !valid() || loadingSubmit

  const addPerson = () => {
    let newPerson = {
      title: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      type: 'COINSURED',
    }
    setData({
      people: [...data.people, newPerson],
    })
  }

  const submit = () => {
    let body = JSON.stringify({
      car_id: contact?.car_id,
      contact_id: contact.id,
      insurer: 'Allianz',
      user_id: contact.user_id,
      application_data: {
        employmentStatus: employmentStatus,
        residentalStatus: residentalStatus,
        financeMethodCode: financeMethodCode,
        birthday: birthday,
        vehicleUseCode: vehicleUseCode,
        underTwentyFiveFlag: underTwentyFiveFlag,
        policyInsureds: data.people,
        garageCode: garageCode,
        annualDistanceCode: annualDistanceCode,
        ownOtherVehicleFlag: ownOtherVehicleFlag,
        genderCode: genderCode,
        addressDescription: addressDescription,
        placeId: placeId,
      },
    })

    setLoadingSubmit(true)
    fetch(`/dealerships/${dealershipSlug}/insurance_policy`, {
      method: 'POST',
      headers: standardHeaders,
      body: body,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error: ${res.status} - ${res.statusText}`)
        }
        return res.json()
      })
      .then((res) => {
        setInsurancePolicy(res)
        setLoadingSubmit(false)
      })
      .catch((error) => {
        console.error('Error submitting insurance policy:', error)
        setLoadingSubmit(false)
      })
  }

  return (
    <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }} orientation="vertical">
      <StepperPanel header="Step I">
        <div>
          <h4>Customer Consent</h4>
          <p>
            In line with the Allianz Financial Services privacy policy, we need your consent to
            share your personal information with Allianz, who is the underwriter of Blinkr Premier
            Motor Insurance.
          </p>
          <Checkbox
            label="By proceeding, you confirm that you have read the above statement to the customer/s and have obtained their consent to proceed."
            checked={consent}
            onChange={() => setConsent(!consent)}
            id="consent"
          />
        </div>
        <NextButton disabled={!consent} onClick={() => stepperRef.current.nextCallback()} />
      </StepperPanel>
      <StepperPanel header="Step II">
        {data.people.map((person, index) => (
          <InsuredPerson person={person} data={data} setData={setData} key={`person-${index}`} />
        ))}
        <div className="btn btn-outline-danger btn-sm mb-2" onClick={addPerson}>
          <i className="fa fa-plus mr-2" />
          Person
        </div>
        <div>
          <NextButton onClick={() => stepperRef.current.nextCallback()} />
        </div>
      </StepperPanel>
      <StepperPanel header="Step III">
        <GooglePlacesAutoComplete
          label="Vehicle Garaging Address"
          required={true}
          defaultValue={addressDescription}
          onChange={(e) => {
            setPlaceId(e.place_id)
            setAddressDescription(e.description)
          }}
        />
        <Select
          label="How is your vehicle financed?"
          options={financeMethodCodes}
          value={financeMethodCodes.filter((e) => e.value === financeMethodCode)[0]}
          onChange={(e) => setFinanceMethodCode(e.value)}
        />
        <Select
          label="Employment Status"
          options={employmentOptions}
          value={employmentOptions.filter((e) => e.value === employmentStatus)[0]}
          onChange={(e) => setEmploymentStatus(e.value)}
        />
        <Select
          label="Residential Status"
          options={residentialOptions}
          value={residentialOptions.filter((e) => e.value === residentalStatus)[0]}
          onChange={(e) => setResidentialOptions(e.value)}
        />
        <NextButton onClick={() => stepperRef.current.nextCallback()} />
      </StepperPanel>
      <StepperPanel header="Step IV">
        <Input
          label="Birthday"
          placeholder="dd/mm/yyyy"
          required={true}
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
        />
        <Select
          label="Are there are any drivers under 25 years old?"
          options={booleanOptions}
          value={booleanOptions.filter((e) => e.value === underTwentyFiveFlag)[0]}
          onChange={(e) => setUnderTwentyFiveFlag(e.value)}
        />
        <Select
          label="What is your gender?"
          options={genderCodeOptions}
          onChange={(e) => setGenderCode(e.value)}
          value={genderCodeOptions.filter((o) => o.value == genderCode)[0]}
          required={true}
        />
        <Select
          label="Does the policy holder own any other vehicles?"
          options={booleanOptions}
          value={booleanOptions.filter((e) => e.value === ownOtherVehicleFlag)[0]}
          onChange={(e) => setOwnOtherVehicleFlag(e.value)}
        />
        <NextButton onClick={() => stepperRef.current.nextCallback()} />
      </StepperPanel>
      <StepperPanel header="Step V">
        <Select
          label="Vehicle Use"
          options={useOptions}
          onChange={(e) => setVehicleUseCode(e.value)}
          value={useOptions.filter((o) => o.value == vehicleUseCode)[0]}
        />
        <Select
          label="Garage"
          options={garageOptions}
          onChange={(e) => setGarageCode(e.value)}
          value={garageOptions.filter((o) => o.value == garageCode)[0]}
        />
        <Select
          label="Estimated Annual Distance"
          options={annualDistanceOptions}
          onChange={(e) => setAnnualDistanceCode(e.value)}
          value={annualDistanceOptions.filter((o) => o.value == annualDistanceCode)[0]}
        />
        {insurancePolicy ? (
          <a
            className={'btn btn-block btn-outline-primary'}
            href={insurancePolicy.external_url}
            target="_blank"
          >
            <i className="fa fa-external-link mr-2"></i>
            Proceed to Indicative Quote
          </a>
        ) : (
          <div
            className={'btn btn-primary btn-block' + (disabled ? ' disabled' : '')}
            onClick={submit}
            disabled={disabled}
          >
            {loadingSubmit ? (
              <>
                <i className="fa fa-spinner fa-spin mr-2"></i>
                Loading
              </>
            ) : (
              'Next'
            )}
          </div>
        )}
      </StepperPanel>
    </Stepper>
  )
}

const Insurance = () => {
  return (
    <div className="p-3 bg-white w-100">
      <h4>Allianz Insurance Indicative Quote</h4>
      <p className="text-secondary">
        Quickly and easily complete an insurance application for your customers
      </p>
      <Wrapper />
    </div>
  )
}

export default Insurance
