import { useContext, useEffect, useState } from 'react'
import { TestDriveContext } from '../../../contexts'
import ContactSelector from '../../../shared/ContactSelector'
import VehicleSelector from '../../../shared/VehicleSelector'

interface DataRowProps {
  label: string
  value: string
}

interface DataBlockProps {
  title: string
  details: DataRowProps[]
}

const DataRow = ({ label, value }: DataRowProps) => (
  <div className="row my-3 pl-2">
    <div className="col-md-4">
      <b>{label}</b>
    </div>
    <div className="col-md-8 text-secondary">{value}</div>
  </div>
)

const DataBlock = ({ title, details }: DataBlockProps) => (
  <div className="col-sm-12 col-md-6 md-my-4">
    <div className="card mb-4">
      <div className="card-header">{title}</div>
      <div className="card-body">
        {details.map((detail, index) => (
          <DataRow key={index} label={detail.label} value={detail.value} />
        ))}
      </div>
    </div>
  </div>
)

export const CustomerVehicleData = ({ onUpdateTestDrive }) => {
  const { testDrive }: any = useContext(TestDriveContext)

  if (!testDrive) return null
  const { car, contact } = testDrive
  const [selectedCar, setSelectedCar] = useState(car.id ? car : null)
  const [selectedContact, setSelectedContact] = useState(contact.id ? contact : null)
  const [needsUpdate, setNeedsUpdate] = useState(false)

  const customerDetails = [
    { label: 'First Name', value: contact.first_name },
    { label: 'Last Name', value: contact.last_name },
    { label: 'Email', value: contact.email },
    { label: 'Phone', value: contact.phone_number },
  ]

  const vehicleDetails = [
    { label: 'Make', value: car.make },
    { label: 'Model', value: car.model },
    { label: 'Year', value: car.year },
    { label: 'Stock Number', value: car.stocknum },
  ]

  useEffect(() => {
    if (selectedCar && selectedContact && testDrive.id && needsUpdate) {
      onUpdateTestDrive({
        carId: selectedCar.value,
        contactId: selectedContact.id,
      }).then(() => {
        setNeedsUpdate(false)
      })
    }
  }, [selectedCar, selectedContact])

  const handleSetSelectedCar = (car) => {
    setNeedsUpdate(true)
    setSelectedCar(car)
  }

  const handleSetSelectedContact = (contact) => {
    setNeedsUpdate(true)
    setSelectedContact(contact)
  }
  return (
    <div>
      <div className="row">
        {selectedContact ? (
          <DataBlock title="Customer Data" details={customerDetails} />
        ) : (
          <ContactSelector
            dealership={testDrive.dealership}
            selectedContact={selectedContact}
            setSelectedContact={handleSetSelectedContact}
          />
        )}

        {selectedCar ? (
          <DataBlock title="Vehicle Data" details={vehicleDetails} />
        ) : (
          <VehicleSelector
            dealership={testDrive.dealership}
            selectedCar={selectedCar}
            setSelectedCar={handleSetSelectedCar}
          />
        )}
      </div>
    </div>
  )
}
