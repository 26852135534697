import { useState, useContext, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Sidebar } from 'primereact/sidebar'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Input, Errors, Checkbox, Select, PhoneInput, TextArea } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'
import { useParams } from 'react-router-dom'
import { DealershipContext } from '../contexts'

let roleOptions = [
  { value: 'default', label: 'Default' },
  { value: 'manager', label: 'Manager' },
]

const Form = ({ user, formOptions, submitUrl, method }) => {
  let { dealership } = useContext(DealershipContext)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])

  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: user?.name || '',
      position: user?.position || '',
      email: user?.email || '',
      phone: user?.phone || '',
      show_on_website: user?.show_on_website ?? true,
      exclude_from_google_reviews: user?.exclude_from_google_reviews ?? false,
      receive_google_review_notifications: user?.receive_google_review_notifications ?? true,
      skip_invitation: false,
      dealership_users_attributes: {
        0: {
          id: user?.dealership_user?.id || null, // Include the id if it exists
          dealership_id: dealership?.id,
          role: user?.dealership_user?.role || 'default',
          can_manage_cars: user?.dealership_user?.can_manage_cars ?? true,
          can_manage_leads: user?.dealership_user?.can_manage_leads ?? true,
          can_manage_customers: user?.dealership_user?.can_manage_customers ?? true,
          can_manage_qr_codes: user?.dealership_user?.can_manage_qr_codes ?? true,
          can_manage_reviews: user?.dealership_user?.can_manage_reviews ?? true,
          can_manage_warranties: user?.dealership_user?.can_manage_warranties ?? false,
          can_download_leads_csv: user?.dealership_user?.can_download_leads_csv ?? false,
          location_ids: user?.dealership_user?.location_ids || [],
          lead_categories: user?.dealership_user?.lead_categories || [],
          can_only_see_assigned_leads: user?.dealership_user?.can_only_see_assigned_leads ?? false,
          can_be_assigned_to_leads: user?.dealership_user?.can_be_assigned_to_leads ?? true,
          bio: user?.dealership_user?.bio?.body || '',
          order: user?.dealership_user?.order || '',
          category: user?.dealership_user?.category || '',
          enquiries_disabled: user?.dealership_user?.enquiries_disabled ?? false,
          position: user?.dealership_user?.position || '',
        },
      },
    },
  })

  const submit = (data) => {
    setLoading(true)
    let url = submitUrl

    fetch(url, {
      method: method,
      headers: standardHeaders,
      body: JSON.stringify({ user: data }),
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            setLoading(false)
            setErrors(data)
            return Promise.reject(data)
          })
        }
        if (response.status === 500) {
          setLoading(false)
          return Promise.reject()
        }
        return response.json() // Handle other statuses normally
      })
      .then(() => {
        window.location.reload()
        // setLoading(false);
        // setVisible(false);
      })
  }

  const positionOpitons = Array.from({ length: formOptions.total_users }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }))

  let dealershipUserCategories = formOptions.dealership_user_categories.map((cat) => ({
    value: cat,
    label: cat,
  }))

  return (
    <form>
      <div className="py-3">
        <Accordion activeIndex={0}>
          <AccordionTab header="Details">
            <div className="row py-2">
              <div className="col">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Input type="text" label="Name" placeholder="Name" required={true} {...field} />
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="position"
                  control={control}
                  render={({ field }) => (
                    <Input type="text" label="Position" placeholder="Position" {...field} />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="email"
                      label="Email"
                      placeholder="Email"
                      required={true}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput type="tel" label="Phone" placeholder="Phone" {...field} />
                  )}
                />
              </div>
            </div>
            <Controller
              name="show_on_website"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} label="Show on website" wrapperClass="" />
              )}
            />
            <Controller
              name="exclude_from_google_reviews"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} label="Exclude from Google Review" wrapperClass="" />
              )}
            />
            <Controller
              name="receive_google_review_notifications"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} label="Receive Google Review notifications" wrapperClass="" />
              )}
            />
            {!user && (
              <Controller
                name="skip_invitation"
                control={control}
                render={({ field }) => <Checkbox {...field} label="Do not send email invitation" />}
              />
            )}
          </AccordionTab>
          <AccordionTab header="Permissions">
            <div className="py-2">
              <Controller
                name="dealership_users_attributes[0].role" // Updated to match your example field name
                control={control}
                defaultValue={roleOptions[0]} // Set the default value as per your requirements
                render={({ field }) => (
                  <Select
                    {...field}
                    options={roleOptions} // The options for the select dropdown
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption ? selectedOption.value : null) // Update to handle single selection
                    }}
                    value={
                      roleOptions.find((option) => option.value === field.value) || roleOptions[0]
                    } // Match the selected option by value
                    label="Role"
                  />
                )}
              />
              <div className="row">
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_manage_cars"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Manage Cars" />}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_manage_leads"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Manage Leads" />}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_manage_customers"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Manage Customers" />}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_manage_qr_codes"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Manage QR Codes" />}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_manage_reviews"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Manage Reviews" />}
                  />
                </div>
                <div className="col-md-3">
                  <Controller
                    name="dealership_users_attributes[0].can_download_leads_csv"
                    control={control}
                    render={({ field }) => <Checkbox {...field} label="Can Download Leads" />}
                  />
                </div>
              </div>
              <Controller
                name="dealership_users_attributes[0].location_ids"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={dealership.locations.map((location) => ({
                      value: location.id,
                      label: location.name,
                    }))}
                    onChange={(selectedOptions) => {
                      field.onChange(
                        selectedOptions ? selectedOptions.map((option) => option.value) : []
                      )
                    }}
                    value={dealership.locations
                      .filter((location) => field.value.includes(location.id))
                      .map((location) => ({ value: location.id, label: location.name }))}
                    label="Locations"
                    hint="Can only view cars, leads etc from the following locations:"
                  />
                )}
              />
              <Controller
                name="dealership_users_attributes[0].lead_categories"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={formOptions.lead_categories.map((cat) => ({ value: cat, label: cat }))}
                    onChange={(selectedOptions) => {
                      field.onChange(
                        selectedOptions ? selectedOptions.map((option) => option.value) : []
                      )
                    }}
                    value={formOptions.lead_categories
                      .filter((cat) => field.value.includes(cat))
                      .map((cat) => ({ value: cat, label: cat }))}
                    label="Lead Categories"
                    hint="Limit the user to ONLY see leads from these categories. If blank user will see all lead categories."
                  />
                )}
              />
              <Controller
                name="dealership_users_attributes[0].can_only_see_assigned_leads"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} label="Can only see assigned leads" wrapperClass="" />
                )}
              />
              <Controller
                name="dealership_users_attributes[0].can_be_assigned_to_leads"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} label="Can be assigned to leads" wrapperClass="" />
                )}
              />
            </div>
          </AccordionTab>
          <AccordionTab header="Team Profile">
            <div className="py-2">
              <Controller
                name="dealership_users_attributes[0].bio"
                control={control}
                render={({ field }) => <TextArea {...field} label="Bio" />}
              />
              <Controller
                name="dealership_users_attributes[0].position"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Position"
                    options={positionOpitons}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption ? selectedOption.value : null)
                    }}
                    value={
                      positionOpitons.find((option) => option.value === field.value) ||
                      positionOpitons[0]
                    }
                  />
                )}
              />
              <Controller
                name="dealership_users_attributes[0].category"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Category"
                    options={dealershipUserCategories}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption ? selectedOption.value : null)
                    }}
                    value={
                      dealershipUserCategories.find((option) => option.value === field.value) ||
                      dealershipUserCategories[0]
                    }
                  />
                )}
              />
            </div>
          </AccordionTab>
        </Accordion>
      </div>

      <Errors errors={errors} />
      <button
        className={'btn btn-secondary' + (loading ? ' disabled' : '')}
        onClick={handleSubmit(submit)}
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Submit'}
      </button>
    </form>
  )
}

export const EditUserSidebar = ({ userId, visible, setVisible }) => {
  let [loading, setLoading] = useState(true)
  let [formOptions, setFormOptions] = useState([])
  let { dealershipSlug } = useParams()
  let [user, setUser] = useState({})

  useEffect(() => {
    if (!visible) return

    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/users/${userId}/edit.json`)
      .then((res) => res.json())
      .then((data) => {
        setFormOptions(data.form_options)
        setUser(data.user)
        setLoading(false)
      })
  }, [visible])

  return (
    <>
      <Sidebar
        header={`Editing User ${user?.name}`}
        visible={visible}
        style={{ width: '1000px' }}
        onHide={() => setVisible(false)}
        position="right"
      >
        {loading ? (
          'Loading...'
        ) : (
          <Form
            formOptions={formOptions}
            user={user}
            submitUrl={`/dealerships/${dealershipSlug}/users/${userId}`}
            method="PATCH"
          />
        )}
      </Sidebar>
    </>
  )
}

export const NewUserSidebar = () => {
  let [visible, setVisible] = useState(false)
  let [loading, setLoading] = useState(true)
  let [formOptions, setFormOptions] = useState([])
  let { dealershipSlug } = useParams()

  useEffect(() => {
    if (!visible) return

    setLoading(true)
    fetch(`/dealerships/${dealershipSlug}/users/new.json`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setFormOptions(data.form_options)
        setLoading(false)
      })
  }, [visible])

  return (
    <>
      <button className="btn btn-outline-success" onClick={() => setVisible(true)}>
        <i className="fa fa-plus mr-2"></i>
        User
      </button>
      <Sidebar
        header="New User"
        visible={visible}
        style={{ width: '1000px' }}
        onHide={() => setVisible(false)}
        position="right"
      >
        {loading ? (
          'Loading...'
        ) : (
          <Form
            formOptions={formOptions}
            submitUrl={`/dealerships/${dealershipSlug}/users`}
            method="POST"
          />
        )}
      </Sidebar>
    </>
  )
}

export default Form
