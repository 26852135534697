import React, { useEffect, useState } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import LoginForm from './LoginForm'

type AppDetails = {
  app_name: string
  subtitle: string
  image_url: string
  dealerships: null
}

const WestSideAutoIndexPage: React.FC = () => {
  const [data, setData] = useState<AppDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false)

  useEffect(() => {
    const fetchAppDetails = async () => {
      try {
        setLoading(true)

        const response = await fetch('/welcome/app_details.json')

        if (!response.ok) {
          throw new Error('Error fetch app details')
        }

        const appDetails = await response.json()

        setData(appDetails)
      } catch (error) {
        console.error('Failed to fetch app details:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchAppDetails()
  }, [])

  if (loading) {
    return (
      <div className="flex-fill d-flex align-items-center justify-content-center">
        <ProgressSpinner />
      </div>
    )
  }

  return (
    <div className="flex-fill d-flex align-items-center justify-content-center westside-auto-home-page">
      <div className="container">
        <div className="row d-flex">
          <div className="d-flex flex-fill mh-100 justify-content-center">
            {/* Left Section */}
            <div className="col-md-5 d-none d-md-flex align-items-center justify-content-center bg-teal text-white p-4 left-pane">
              <div className="py-5 px-3 text-center">
                <img
                  src={data?.image_url}
                  className="py-4 logo-index westside-auto-logo"
                  alt={data?.app_name}
                />

                <div className="my-5 py-5 text-left">
                  <p className="h4">Simplified</p>
                  <p className="h4 text-grey">Inventory</p>
                  <p className="h4 text-grey">Management</p>
                  <small className="mt-3">
                    Up-to-date inventory with real-time status, modifications, and configurations –
                    streamlining operations.
                  </small>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-md-5 d-flex align-items-top justify-content-center bg-dark text-white mx-4 mx-md-0 right-pane">
              <div className="d-flex flex-column pt-5 text-center w-100">
                <div className="flex-grow-1">
                  <h2 className="mb-1">Welcome</h2>
                  <p>Access is provided by your IT department</p>

                  {showLoginForm ? (
                    <LoginForm ssoEnabled={false} isMobileClient={false} />
                  ) : (
                    <div className="py-5">
                      <a
                        className="btn btn-light btn-lg"
                        rel="nofollow"
                        data-method="post"
                        href="/users/auth/saml"
                      >
                        <i className="fab fa-microsoft mr-2"></i> Sign in with Microsoft
                      </a>
                    </div>
                  )}
                </div>

                <div className="mt-auto d-flex justify-content-end">
                  <button
                    className="btn text-white"
                    onClick={() => setShowLoginForm(!showLoginForm)}
                  >
                    <i className="fa-regular fa-pi"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WestSideAutoIndexPage
