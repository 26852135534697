import { useEffect, useState } from 'react'
import Select from 'react-select'
import { humanize } from '../entries/utils'

const SelectState = ({
  hit,
  attribute = 'lead_state',
  placeholder = 'Status',
  onStateChange,
  selectedValue,
  disabled,
  leadStatusOptions = false,
  leadCluster,
  setLeadCluster,
}) => {
  if (!leadStatusOptions) {
    return <></>
  }

  let combined_options = leadStatusOptions
    .sort((a, b) => b.position - a.position)
    .map((option) => {
      return {
        ...option,
        label: humanize(option.value),
      }
    })

  let [loading, setLoading] = useState(false)
  let [value, setValue] = useState(combined_options.filter((u) => u.value === hit[attribute])[0])

  useEffect(() => {
    setValue(combined_options.filter((u) => u.value === (selectedValue || hit[attribute]))[0])
  }, [selectedValue, hit[attribute]])

  const onChange = async ({ value, colour, label }) => {
    setLoading(true)
    setValue({ value, colour, label })
    await onStateChange(hit.id, value, leadCluster, setLeadCluster)
    setLoading(false)
  }

  const customStyles = {
    singleValue: (provided, state) => {
      let custom = {
        background: `var(--${value?.colour})`,
        color: 'white',
        padding: '0.25rem 0.5rem',
        borderRadius: '0.5rem',
      }

      return { ...provided, ...custom }
    },
  }

  return (
    <Select
      options={combined_options}
      name={attribute}
      isLoading={loading}
      value={value}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder}
      isDisabled={disabled}
      className="state-select"
      menuPortalTarget={document.body}
    />
  )
}

export default SelectState
