import { useState } from 'react'
import SortPopup from './SortPopup'

const SortableUsers = () => {
  let [visible, setVisible] = useState(false)
  return (
    <>
      <div className="dropdown-item" onClick={() => setVisible(true)}>
        Sort Users
      </div>
      <SortPopup visible={visible} setVisible={setVisible} />
    </>
  )
}

export default SortableUsers
