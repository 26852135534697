import { useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Input, Switch } from '../entries/FormElements'
import { standardHeaders } from '../entries/utils'

let WebsiteForm = ({ website, visible, setVisible }) => {
  let [name, setName] = useState(website.name)
  let [url, setUrl] = useState(website.url)
  let [dealerstudio, setDealerstudio] = useState(website.dealerstudio)
  let [loading, setLoading] = useState(false)

  let updateWebsite = () => {
    setLoading(true)
    fetch(`/websites/${website.id}`, {
      method: 'PATCH',
      headers: standardHeaders,
      body: JSON.stringify({
        website: {
          name: name,
          url: url,
          dealerstudio: dealerstudio,
        },
      }),
    })
      .then((res) => res.json())
      .then(() => {
        setLoading(false)
        window.location.reload()
      })
  }

  return (
    <>
      <Dialog
        header={'Edit Website'}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        dismissableMask={true}
      >
        <Input name="name" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <Input name="url" label="URL" value={url} onChange={(e) => setUrl(e.target.value)} />
        <div className="mb-3">
          <Switch
            label="Dealer Studio"
            id="dealerstudio-id"
            value={dealerstudio}
            onChange={(e) => setDealerstudio(!dealerstudio)}
          />
          <small className="form-text text-muted">
            Is website hosted by Dealer Studio?{' '}
            <b>If this is turned OFF the stock will NOT be updated</b>
          </small>
        </div>
        <div
          className={'btn btn-outline-primary btn-block' + (loading ? ' disabled' : '')}
          onClick={updateWebsite}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Update'}
        </div>
      </Dialog>
    </>
  )
}

export default WebsiteForm
