import React from 'react'
import { useConfig } from '../../../contexts/hooks'

export const LayoutSizeComponent: React.FC = () => {
  const { config, handleUpdateConfig } = useConfig()
  return (
    <div className="d-flex justify-content-center align-items-center mr-1">
      <span className="mr-2 mb-0">Layout size:</span>
      <span
        className={`mr-2 btn px-4 ml-auto ${
          config?.layoutSize === 'small' ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => handleUpdateConfig({ layoutSize: 'small' })}
      >
        S
      </span>
      <span
        className={`mr-2 btn px-4 ml-auto ${
          config?.layoutSize === 'medium' ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => handleUpdateConfig({ layoutSize: 'medium' })}
      >
        M
      </span>
      <span
        className={`mr-2 btn px-4 ml-auto ${
          config?.layoutSize === 'large' ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => handleUpdateConfig({ layoutSize: 'large' })}
      >
        L
      </span>
    </div>
  )
}
export default LayoutSizeComponent
