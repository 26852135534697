import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CarLoanContext,
  DealershipContext,
  ManufacturerContext,
  TestDriveContext,
  WebsiteContext,
} from './contexts'

/**
 * Explanation: The context providers are set up outside of the Routes component in App.js.
 * So we wait until the child components have loaded before we fetch the full JSON objects,
 * because the child components have access to the Route params (useParams) and the context providers.
 */
export const useFetchDealership = (dealershipId = null) => {
  const { dealershipSlug } = useParams()
  const { websiteSlug } = useParams()
  const { dealership, setDealership } = useContext(DealershipContext)
  const [loading, setLoading] = useState(!dealership)

  useEffect(() => {
    const fetchDealerhship = async () => {
      if (dealership || (!dealershipSlug && !dealershipId && !websiteSlug)) {
        setLoading(false)
        return
      }
      setLoading(true)

      try {
        if (dealershipSlug || dealershipId) {
          const response = await fetch(`/dealerships/${dealershipSlug || dealershipId}.json`)
          const data = await response.json()
          setDealership(data)
        } else if (websiteSlug) {
          const websiteResponse = await fetch(`/websites/${websiteSlug}.json`)
          const websiteData = await websiteResponse.json()
          const dealershipResponse = await fetch(`/dealerships/${websiteData.dealership_id}.json`)
          const dealershipData = await dealershipResponse.json()
          setDealership(dealershipData)
        }
      } catch (error) {
        console.error('Error fetching dealership data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchDealerhship()
  }, [dealership, dealershipSlug, dealershipId, websiteSlug, setDealership])

  return { dealership, loading }
}

export const useFetchWebsite = () => {
  const { websiteSlug } = useParams()
  const { website, setWebsite } = useContext(WebsiteContext)
  const [loading, setLoading] = useState(!website)

  useEffect(() => {
    const fetchWebsite = async () => {
      if (website || !websiteSlug) {
        setLoading(false)
        return
      }
      setLoading(true)

      try {
        const response = await fetch(`/websites/${websiteSlug}.json`)
        const data = await response.json()
        setWebsite(data)
      } catch (error) {
        console.error('Error fetching website data:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchWebsite()
  }, [website, websiteSlug, setWebsite])

  return { website, loading }
}

export const useFetchManufacturer = () => {
  const { manufacturerSlug } = useParams()
  const { manufacturer, setManufacturer } = useContext(ManufacturerContext)

  useEffect(() => {
    const fetchManufacturerData = async () => {
      if (!manufacturer && manufacturerSlug) {
        try {
          const response = await fetch(`/manufacturers/${manufacturerSlug}.json`)
          const data = await response.json()
          setManufacturer(data)
        } catch (error) {
          console.error('Error fetching manufacturer data:', error)
        }
      }
    }
    fetchManufacturerData()
  }, [manufacturer, manufacturerSlug, setManufacturer])

  return manufacturer
}

export const useFetchTestDrive = async () => {
  const params = useParams()
  const testDriveId = params.testDriveId || null
  const { testDrive, setTestDrive } = useContext(TestDriveContext)

  if (!testDrive && testDriveId) {
    try {
      const response = await fetch(
        `/dealerships/${window.dealer_slug}/test_drives/${testDriveId}.json`
      )
      const data = await response.json()
      setTestDrive(data)
    } catch (error) {
      console.error('Error fetching test drive data:', error)
    }
  }
}

export const useFetchCarLoan = async () => {
  const params = useParams()
  const carLoanId = params.carLoanId || null
  const { carLoan, setCarLoan } = useContext(CarLoanContext)

  if (!carLoan && carLoanId) {
    try {
      const response = await fetch(`/dealerships/${window.dealer_slug}/car_loans/${carLoanId}.json`)
      const data = await response.json()
      setCarLoan(data)
    } catch (error) {
      console.error('Error fetching car loan data:', error)
    }
  }
}
