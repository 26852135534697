import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Item from './Item'
import type { ModuleItem } from '../../../types'

type SortableItemProps = {
  id: string
  item: ModuleItem
  itemIndex?: number
  rowWidth?: number
}

const SortableItem: React.FC<SortableItemProps> = ({ id, item, itemIndex, rowWidth }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  const draghandleProps = { ...listeners, ...attributes }

  return (
    <>
      <Item
        id={id}
        item={item}
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        draghandleProps={draghandleProps}
        isDragging={isDragging}
        itemIndex={itemIndex}
        rowWidth={rowWidth}
      />
    </>
  )
}

export default SortableItem
