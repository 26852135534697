import { SelectFetch } from 'react-select-fetch'
import * as Routes from '../../routes'

export const VehicleSelector = ({ dealership, selectedCar, setSelectedCar }) => {
  return (
    <div className="col-md-6">
      <div id="select-car" className="card mb-4">
        <div className="card-header">Select a Vehicle</div>
        <div className="card-body">
          <SelectFetch
            value={selectedCar}
            url={Routes.dealership_cars_path(dealership.id) + '.json'}
            mapResponse={(response: any) => ({
              options: response,
              hasMore: false,
            })}
            onChange={setSelectedCar}
            queryParams={{ for_dropdown: 1 }}
          />
        </div>
      </div>
    </div>
  )
}

export default VehicleSelector
