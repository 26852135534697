import { useParams } from 'react-router-dom'
import { useRef, useState } from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Toast } from 'primereact/toast'

import { fetchOnClient, useQuery } from '../tanstackPlaceholderUtils'
import * as Routes from '../../routes'
import { formatSimpleDuration } from '../entries/timeAgo'

import type { Checkout } from './CheckoutTypes'
import { Dialog } from 'primereact/dialog'

const Checkouts = () => {
  const { dealershipSlug } = useParams()
  const notification = useRef(null)
  const [selectedCheckout, setSelectedCheckout] = useState<Checkout | null>(null)

  const { data, loading, error } = useQuery<Checkout[]>({
    query: fetchOnClient,
    queryKey: `${Routes.dealership_checkouts_path(dealershipSlug)}.json`,
  })

  if (error) {
    return (
      <div className="p-3">
        <h2>An error occurred</h2>
        <p className="text-danger">{error?.message}</p>
      </div>
    )
  }

  const checkouts = data ?? []

  const dateTemplate = (rowData: Checkout, field: 'created_at' | 'updated_at') => {
    const date = new Date(rowData[field])
    const now = new Date()
    return formatSimpleDuration(date, now) + ' ago'
  }

  return (
    <div className="p-5">
      <Toast ref={notification} />
      <div className="px-3 py-1">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-3">
              <h2>Checkouts</h2>
              <p>View and manage customer checkouts</p>
            </div>

            <DataTable value={checkouts} loading={loading}>
              <Column
                field="contact"
                header="Contact"
                body={(rowData) => (
                  <a href={`${Routes.dealership_contact_path(dealershipSlug, rowData.contact_id)}`}>
                    {rowData.contact_id}
                  </a>
                )}
              />
              <Column
                field="created_at"
                header="Started"
                body={(rowData) => dateTemplate(rowData, 'created_at')}
              />
              <Column
                field="updated_at"
                header="Last Updated"
                body={(rowData) => dateTemplate(rowData, 'updated_at')}
              />
              <Column
                field="formdata.progress"
                header="Progress"
                body={(rowData) => (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    style={{ borderRadius: '999px' }}
                    onClick={() => setSelectedCheckout(rowData)}
                  >
                    {rowData?.formdata?.progress ?? 0}% <i className="fas fa-eye"></i>
                  </button>
                )}
              />
              <Column
                field="valuation"
                header="Valuation"
                body={(rowData) => {
                  if (!rowData.valuation?.make || !rowData.valuation?.model) {
                    return 'N/A'
                  }
                  return (
                    <a
                      href={`${Routes.dealership_valuation_path(dealershipSlug, rowData.valuation_id)}`}
                    >
                      {rowData.valuation?.make} {rowData.valuation?.model} -
                      {rowData.valuation?.offer_price > 0
                        ? `$${rowData.valuation?.offer_price.toLocaleString()}`
                        : 'N/A'}
                    </a>
                  )
                }}
              />
              <Column
                field="car.name"
                header="Car"
                body={(rowData) => <a href={`/cars/${rowData.car.slug}`}>{rowData.car.name}</a>}
              />
              <Column field="website.name" header="Website" />
            </DataTable>
            <Dialog
              header="Checkout Form Data"
              visible={selectedCheckout !== null}
              style={{ width: '50vw' }}
              onHide={() => setSelectedCheckout(null)}
            >
              <ul>
                {Object.entries(selectedCheckout?.formdata ?? {}).map(([key, value]) => {
                  if (typeof value === 'object' || typeof value === 'symbol') {
                    return null
                  }
                  return (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  )
                })}
              </ul>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkouts
