import { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toggle } from '../settings/leadSettings'

const Name = (dealership) => {
  return <a href={`/dealerships/${dealership.slug}`}>{dealership.name}</a>
}

const TwoFactor = (dealership) => {
  return (
    <Toggle
      attributeName="enforce_two_factor"
      objectType="dealership"
      rowData={dealership}
      url={`/dealerships/${dealership.id}`}
    />
  )
}

const Dealerships = () => {
  const [dealerships, setDealerships] = useState([])
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetch('/dealerships.json')
      .then((response) => response.json())
      .then((data) => {
        setDealerships(data)
        setLoading(false)
      })
  }, [])

  return (
    <div className="container py-4">
      <div className="d-flex mb-2">
        <h4>Dealerships</h4>
        <div className="ml-auto">
          <a href="/dealerships/new" className="btn btn-outline-primary">
            <i className="fa fa-plus mr-2"></i>
            Dealership
          </a>
        </div>
      </div>
      <div className="box">
        <DataTable value={dealerships} loading={loading}>
          <Column field="name" header="Name" body={Name} />
          <Column field="two_factor" header="Enforce Two Factor" body={TwoFactor} />
        </DataTable>
      </div>
    </div>
  )
}

export default Dealerships
