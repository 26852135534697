import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import showToast from './../shared/ShowToast'

import { getInitials, standardHeaders } from '../entries/utils'
import { ownerContext } from './utils'
import { useFetchDealership } from './../dataHooks'
import { LeadClusterContext } from '../contexts'

const AvatarInitials = ({ name }) => {
  let initials = getInitials(name)
  return <div className={`d-none d-md-flex AvatarInitials mr-3`}>{initials}</div>
}

const Message = ({ leadCluster, leadClusterEmail, toDealership }) => {
  const formattedDate = format(new Date(leadClusterEmail.created_at), 'dd/MM/yyyy HH:mm')

  return (
    <>
      <div className={`${toDealership ? 'col-12 d-flex' : 'col-md-10 offset-md-2'} mt-3`}>
        {toDealership && (
          <div className="pt-1">
            <AvatarInitials
              name={`${leadCluster.contact.first_name} ${leadCluster.contact.last_name}`}
            />
          </div>
        )}
        <div
          className={`${toDealership ? 'from-them flex-fill' : 'from-me'} card`}
          id={`lead-cluster-email-${leadClusterEmail.id}`}
        >
          <div className="card-header d-flex justify-content-between">
            <b>{leadClusterEmail.subject}</b>
            <div className={`${toDealership ? 'text-secondary' : ''}`}>{formattedDate}</div>
          </div>
          <div className="card-body">
            <div className="text-secondary mb-2">{leadClusterEmail.from}</div>
            <pre className="mb-0">{leadClusterEmail.body}</pre>
          </div>
        </div>
      </div>
    </>
  )
}

const EmailConversation = () => {
  const { notification } = useContext(LeadClusterContext)
  const { dealership } = useFetchDealership()
  const { owner } = ownerContext()
  let [emails, setEmails] = useState([])
  let [loading, setLoading] = useState(false)
  let [message, setMessage] = useState('')
  let [subject, setSubject] = useState('')

  useEffect(() => {
    const element = document.getElementById('email-conversation-scroll-container')
    element.scrollTop = element.scrollHeight
  }, [emails])

  useEffect(() => {
    fetchLeadClusterEmails()
    const intervalId = setInterval(() => {
      fetchLeadClusterEmails()
    }, 60_000)

    // Cleanup interval on component unmount or when dealershipId changes (component re-renders)
    return () => clearInterval(intervalId)
  }, [owner])

  const fetchLeadClusterEmails = async () => {
    try {
      const URL = Routes.lead_cluster_emails_path({ lead_cluster_id: owner.id })
      const response = await fetch(URL)
      const data = await response.json()
      setEmails(data)
    } catch (error) {
      console.error('Error fetching lead cluster emails:', error)
      showToast(notification, 'error', 'Error fetching lead cluster emails.', '')
    }
  }

  const onSubmit = () => {
    setLoading(true)
    fetch(Routes.send_to_lead_cluster_lead_cluster_emails_path(), {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        lead_cluster_id: owner.id,
        body: message,
        subject,
      }),
    })
      .then(async (_response) => {
        setMessage('')
        setSubject('')
        await fetchLeadClusterEmails()
      })
      .catch((error) => {
        console.error('Error sending email:', error)
        showToast(notification, 'error', 'Error sending email.', '')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="mx-3 mt-2 mb-2 email-conversation">
      <div
        id="email-conversation-scroll-container"
        className="container border rounded mb-2 overflow-auto"
      >
        <div className="row mb-0 pb-3 bg-white">
          {emails
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((leadClusterEmail) => (
              <Message
                key={leadClusterEmail.id}
                leadCluster={owner}
                leadClusterEmail={leadClusterEmail}
                toDealership={leadClusterEmail.from == owner.contact.email}
              />
            ))}
        </div>
      </div>
      <div className="border rounded message-form p-3">
        <input
          className="form-control-plaintext"
          readOnly={true}
          value={dealership.email_from_address_for_conversation}
        />
        <hr className="my-0" />
        <input className="form-control-plaintext" readOnly={true} value={owner.contact.email} />
        <hr className="my-0" />
        <input
          className="form-control border-0 p-0"
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject"
        />
        <hr className="mt-0" />
        <textarea
          className="p-0"
          placeholder="Type Your Message..."
          onChange={(e) => setMessage(e.target.value)}
          rows="5"
          value={message}
        />
        <button disabled={message === '' || loading} onClick={onSubmit}>
          {loading ? 'Loading...' : 'Send'}
        </button>
      </div>
    </div>
  )
}

export default EmailConversation
