import { useState } from 'react'
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete'
import { Label } from './FormElements'

const GooglePlacesInput = ({ label, required, defaultValue, onChange }) => {
  const [selectedAddress, setSelectedAddress] = useState(null)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here if needed */
    },
    debounce: 300,
  })

  const handleInputChange = (e) => {
    setValue(e.target.value)
  }

  const handleSelect = async (suggestion) => {
    setValue(suggestion.description, false)
    clearSuggestions()
    onChange(suggestion)

    // try {
    //   const results = await getGeocode({ address: description });
    //   const { lat, lng } = await getLatLng(results[0]);
    //   // console.log('Coordinates: ', { lat, lng });
    //   console.log(results)
    //   setSelectedAddress({ address: description, lat, lng });
    // } catch (error) {
    //   console.error('Error: ', error);
    // }
  }

  return (
    <div className="form-group">
      {label && <Label required={required} label={label} />}
      <input
        value={value || defaultValue}
        onChange={handleInputChange}
        disabled={!ready}
        placeholder="Search Places..."
        className="form-control"
      />
      {status === 'OK' && (
        <div
          style={{
            border: '1px solid #e0e0e0',
            backgroundColor: '#ffffff',
            position: 'absolute',
            zIndex: 1000,
          }}
        >
          {data.map((suggestion) => {
            return (
              <div
                key={suggestion.place_id}
                onClick={() => handleSelect(suggestion)}
                style={{ padding: '8px', cursor: 'pointer', backgroundColor: '#ffffff' }}
              >
                {suggestion.description}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default GooglePlacesInput
