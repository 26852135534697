// EditAdAccount.tsx
import React, { useContext, useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Checkbox } from 'primereact/checkbox'
import { standardHeaders } from '../../../../entries/utils'
import Loading from '../../../../Loading'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { useFetchDealership } from '../../../../dataHooks'
import { CurrentUserContext, DealershipContext } from '../../../../contexts'
import type { SetState, User } from '../../../types'
import type { Campaign, CampaignsState, Website } from './SocialAdAccounts'
import { capitalize } from '../../../../editor/common/Utils'

type EditAdAccountProps = {
  id: number
  name: string
  dealershipSlug: string
  dealershipName: string
  dealershipId: number
  provider: 'Facebook' | 'Google'
  externalId: string
  userIsAuthorised: boolean
  campaigns: CampaignsState
  setCampaigns: SetState<CampaignsState>
  autoAssignedWebsites: Website[]
  setAutoAssignedWebsites: SetState<Website[]>
}

type Dealership = {
  id: number
  name: string
  slug?: string
  city?: string
  city_slug?: string
  websites: Website[]
}

const EditAdAccount: React.FC<EditAdAccountProps> = ({
  id,
  name,
  dealershipName,
  dealershipId,
  dealershipSlug,
  provider,
  externalId,
  userIsAuthorised,
  campaigns,
  setCampaigns,
  autoAssignedWebsites,
  setAutoAssignedWebsites,
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
  const [selectedAdAccountExternalId, setSelectedAdAccountExternalId] = useState<string | null>(
    null
  )
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { dealership } = useContext(DealershipContext) as { dealership: Dealership }
  useFetchDealership()

  const currentUser = useContext(CurrentUserContext) as User

  const canEdit = userIsAuthorised || currentUser?.admin

  useEffect(() => {
    const campaignsPresentForAdAccount = campaigns?.[externalId]
    if (externalId === selectedAdAccountExternalId && !campaignsPresentForAdAccount) {
      fetchCampaigns(id, name, externalId, provider)
      fetchWebsitesWithAutoAssign()
    }
  }, [id, selectedAdAccountExternalId])

  const fetchCampaigns = async (
    accountId: number,
    adAccountName: string,
    externalId: string,
    provider: 'Facebook' | 'Google'
  ) => {
    setLoading(true)
    const params = new URLSearchParams({
      external_id: externalId,
      ad_account_name: adAccountName,
      provider,
    }).toString()
    try {
      const URL = `/dealerships/${
        dealership?.slug || dealershipSlug
      }/social_ad_accounts/${accountId}/campaigns.json${params ? `?${params}` : ''}`
      const response = await fetch(URL, {
        method: 'GET',
        headers: standardHeaders,
      })
      const data = await response.json()
      setCampaigns((prevState) => ({
        ...prevState,
        [externalId]:
          data?.campaigns.sort((a: Campaign, b: Campaign) => a.name.localeCompare(b.name)) || [],
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchWebsitesWithAutoAssign = async () => {
    try {
      const URL = `/dealerships/${
        dealership?.slug || dealershipSlug
      }/social_ad_accounts/websites_with_auto_assign.json`
      const response = await fetch(URL, {
        method: 'GET',
        headers: standardHeaders,
      })
      const data = await response.json()
      setAutoAssignedWebsites(data?.websites || [])
    } catch (error) {
      console.error(error)
    }
  }

  const updateCampaignWebsite = (
    externalId: string,
    campaignId: string,
    websiteId: string | null,
    websiteName: string | null
  ): void => {
    setCampaigns((prevState) => ({
      ...prevState,
      [externalId]:
        prevState[externalId].map((campaign) =>
          campaign.id === campaignId
            ? {
                ...campaign,
                websiteId: websiteId,
                websiteName: websiteName,
              }
            : campaign
        ) || [],
    }))
  }

  const updateAllCampaignWebsites = (
    externalId: string,
    websiteId: string | null,
    websiteName: string | null
  ): void => {
    setCampaigns((prevState) => ({
      ...prevState,
      [externalId]:
        prevState[externalId].map((campaign) => ({
          ...campaign,
          websiteId: websiteId,
          websiteName: websiteName,
        })) || [],
    }))
  }

  const onHide = () => {
    setIsDialogVisible(false)
    setSelectedAdAccountExternalId(null)
  }

  return (
    <>
      <div
        onClick={() => {
          setSelectedAdAccountExternalId(externalId)
          setIsDialogVisible(true)
        }}
      >
        <span className="link-like-div">{name}</span>
      </div>
      <Dialog
        header="Edit Ad Account"
        visible={isDialogVisible}
        onHide={onHide}
        dismissableMask
        style={{ width: '80vw' }}
      >
        {loading && (
          <div className="center">
            <Loading />
          </div>
        )}
        {selectedAdAccountExternalId !== null &&
        (dealership || (dealershipName && dealershipId)) &&
        !loading ? (
          <div className="row">
            {/* Ad Account Name */}
            <div className="col-6 mb-3">
              <label>Ad Account:</label>
            </div>
            <div className="col-6 mb-3">
              <input type="text" value={name} readOnly className="form-control" />
            </div>

            {/* Property ID */}
            <div className="col-6 mb-3">
              <label>Property ID:</label>
            </div>
            <div className="col-6 mb-3">
              <input type="text" value={externalId} readOnly className="form-control" />
            </div>

            {/* Dealership Name */}
            <div className="col-6 mb-3">
              <label>Dealership:</label>
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                value={dealership?.name || dealershipName}
                readOnly
                className="form-control"
              />
            </div>
            <div className="col-12">
              <Accordion
                activeIndex={activeAccordionIndex}
                onTabChange={(e) => setActiveAccordionIndex(e.index)}
                className="mb-3"
              >
                <AccordionTab header="Campaigns">
                  <CampaignsTable
                    campaigns={campaigns}
                    dealershipId={dealership?.id || dealershipId}
                    websites={autoAssignedWebsites}
                    externalId={selectedAdAccountExternalId}
                    updateCampaignWebsite={updateCampaignWebsite}
                    updateAllCampaignWebsites={updateAllCampaignWebsites}
                    canEdit={canEdit}
                  />
                </AccordionTab>
                <AccordionTab header="Websites">
                  <WebsitesTable
                    websites={autoAssignedWebsites}
                    canEdit={canEdit}
                    campaigns={campaigns}
                    provider={provider}
                    autoAssignedWebsites={autoAssignedWebsites}
                    setAutoAssignedWebsites={setAutoAssignedWebsites}
                  />
                </AccordionTab>
              </Accordion>
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  )
}

const CampaignsTable: React.FC<{
  campaigns: CampaignsState
  dealershipId: number
  websites: Website[]
  externalId: string
  updateCampaignWebsite: (
    externalId: string,
    campaignId: string,
    websiteId: string | null,
    websiteName: string | null
  ) => void
  updateAllCampaignWebsites: (
    externalId: string,
    websiteId: string | null,
    websiteName: string | null
  ) => void
  canEdit: boolean
}> = ({
  campaigns,
  websites,
  updateCampaignWebsite,
  updateAllCampaignWebsites,
  externalId,
  canEdit,
}) => (
  <>
    <div className="row w-100">
      <div className="col-12 p-0">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Campaign</th>
              <th>Type</th>
              <th>Website</th>
            </tr>
          </thead>
          <tbody>
            {canEdit && (
              <tr style={{ height: '100px' }}>
                <td>
                  <h5>
                    <strong>Assign all campaigns to website:</strong>
                  </h5>
                </td>
                <td />
                <td>
                  <WebsitesDropdown
                    websites={websites}
                    campaignId={'0'}
                    externalId={externalId}
                    updateCampaignWebsite={updateCampaignWebsite}
                    updateAllCampaignWebsites={updateAllCampaignWebsites}
                    assignAll
                  />
                </td>
              </tr>
            )}
            {campaigns?.[externalId].map(({ id, campaignType, name, websiteId, websiteName }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{capitalize(campaignType?.replace('_', ' ')?.toLocaleLowerCase()) || ''}</td>
                <td>
                  {websiteId === null && canEdit ? (
                    <WebsitesDropdown
                      externalId={externalId}
                      websites={websites}
                      campaignId={id}
                      updateCampaignWebsite={updateCampaignWebsite}
                    />
                  ) : (
                    <div>
                      {websiteName}
                      {canEdit ? (
                        <div
                          className="btn fas fa-times-circle"
                          style={{ color: 'red' }}
                          onClick={() => updateCampaignWebsite(externalId, id, null, null)}
                        ></div>
                      ) : null}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </>
)

const WebsitesTable: React.FC<{
  websites: Website[]
  canEdit: boolean
  campaigns: CampaignsState
  provider: 'Facebook' | 'Google'
  autoAssignedWebsites: Website[]
  setAutoAssignedWebsites: SetState<Website[]>
}> = ({
  websites,
  canEdit,
  campaigns,
  provider,
  autoAssignedWebsites,
  setAutoAssignedWebsites,
}) => {
  const handleAutoAssign = (websiteId: string) => {
    if (
      autoAssignedWebsites.some(
        (website) => website.id === websiteId && website.autoAssign !== true
      )
    ) {
      setAutoAssignedWebsites((prevState) =>
        prevState.map((website) =>
          website.id === websiteId ? { ...website, autoAssign: true } : website
        )
      )
    } else {
      setAutoAssignedWebsites((prevState) =>
        prevState.map((website) =>
          website.id === websiteId ? { ...website, autoAssign: false } : website
        )
      )
    }
  }

  return (
    <>
      <div className="row w-100">
        <div className="col-12 p-0">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Website</th>
                <th>No. of Campaigns</th>
                {/* Temporary until we get the auto assign working for google */}
                {provider !== 'Google' && <th>New campaigns will auto assign</th>}
              </tr>
            </thead>
            <tbody>
              {websites.map(({ id, name }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>
                    <span>
                      {/* This checks through all the ad accounts, looking for all campaigns assigned to website  */}
                      {Object.values(campaigns).reduce((count, accountCampaigns) => {
                        const matchedCampaigns = accountCampaigns.filter((campaign) => {
                          return String(campaign.websiteId) === String(id)
                        })
                        return count + matchedCampaigns.length
                      }, 0)}
                    </span>
                  </td>
                  {/* Temporary until we get the auto assign working for google */}
                  {provider !== 'Google' && (
                    <td>
                      {canEdit && (
                        <Checkbox
                          checked={autoAssignedWebsites.some(
                            (website) => website.id === id && website.autoAssign === true
                          )}
                          onChange={() => {
                            // Toggle auto assign
                            handleAutoAssign(id)
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const WebsitesDropdown: React.FC<{
  websites: { id: string; name: string }[]
  campaignId: string
  externalId: string
  updateCampaignWebsite: (
    externalId: string,
    campaignId: string,
    websiteId: string,
    websiteName: string
  ) => void
  updateAllCampaignWebsites?: (externalId: string, websiteId: string, websiteName: string) => void
  assignAll?: boolean
}> = ({
  websites,
  campaignId,
  externalId,
  updateCampaignWebsite,
  assignAll,
  updateAllCampaignWebsites,
}) => {
  const handleChange = (event: { value: { id: number; name: string } }) => {
    if (assignAll && campaignId === '0' && updateAllCampaignWebsites) {
      // Assign to all campaigns
      updateAllCampaignWebsites(externalId, event.value.id.toString(), event.value.name)
    } else
      updateCampaignWebsite(externalId, campaignId, event.value.id.toString(), event.value.name)
  }
  return (
    <Dropdown
      placeholder="Set Website"
      onChange={handleChange}
      options={websites.map((website) => ({
        label: website.name,
        value: { id: website.id, name: website.name },
      }))}
    ></Dropdown>
  )
}

export default EditAdAccount
