import { useState, useEffect, useContext } from 'react'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'

import { Input, TextArea, Select, PhoneInput, Switch, Errors } from '../entries/FormElements'
import Loading from '../Loading'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { standardHeaders } from '../entries/utils'
import { DealershipContext } from '../contexts'

const NewLeadForm = ({ opened = false }) => {
  const navigate = useNavigate()
  const dealership = useContext(DealershipContext)

  const [formData, setFormData] = useState({
    name: '',
    last_name: '',
    phone: '',
    email: '',
    message: '',
  })

  const [category, setCategory] = useState('')
  const [source, setSource] = useState('')
  const [locationId, setLocationId] = useState('')
  const [carId, setCarId] = useState('')
  const [websiteId, setWebsiteId] = useState('')
  const [sendCustomerEmail, setSendCustomerEmail] = useState(
    dealership?.notify_customer_for_manual_leads
  )
  const [optIn, setOptIn] = useState(false)

  const [leadFormData, setLeadFormData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState(false)
  const [testDriveBookingDate, setTestDriveBookingDate] = useState('')
  let [checkingPhone, setCheckingPhone] = useState(false)

  useEffect(() => {
    if (!leadFormData && opened) {
      setLoading(true)
      fetch(`/dealerships/${window.dealer_slug}/leads/new.json`)
        .then((response) => response.json())
        .then((data) => {
          setLeadFormData(data)
          setLoading(false)
        })
    }
  }, [opened])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmitForm(formData)
  }

  const checkIfPhoneExists = (phone) => {
    setCheckingPhone('Checking phone...')
    fetch(
      `/dealerships/${window.dealer_slug}/contacts/check_if_contact_exists.json?phone_number=${phone}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.contact_exists) {
          setCheckingPhone('Phone number already exists')
        } else {
          setCheckingPhone('New phone number')
        }
      })
      .catch(() => {
        setCheckingPhone('Error checking phone')
      })
  }

  const onSubmitForm = (formData) => {
    setLoadingSubmit(true)

    let leadData = {
      category: category,
      source: source,
      location_id: locationId,
      website_id: websiteId,
      send_customer_email: sendCustomerEmail,
      car_id: carId,
      opt_in: optIn,
      disable_strict_validation: true,
      ...formData,
    }

    if (testDriveBookingDate) {
      if (moment(testDriveBookingDate).isValid()) {
        const date = moment(testDriveBookingDate).format('DD/MM/YYYY')
        const time = moment(testDriveBookingDate).format('hh:mma')
        leadData['test_drive_booking_date'] = date
        leadData['preferred_time'] = time
      } else {
        console.error(`Invalid date format: ${testDriveBookingDate} `) // eslint-disable-line no-console
      }
    }

    fetch(`/dealerships/${window.dealer_slug}/leads`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        lead: leadData,
      }),
    })
      .then((response) => {
        if (response.status === 422) {
          return response.json().then((data) => {
            setLoadingSubmit(false)
            setErrors(data)
            return Promise.reject(data)
          })
        }
        return response.json() // Handle other statuses normally
      })
      .then((data) => {
        setLoadingSubmit(false)
        navigate(`/dealerships/${window.dealer_slug}/lead_clusters/${data.lead_cluster_id}`)
      })
  }

  if (loading || !leadFormData) {
    return (
      <div>
        <Loading />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      {errors && <Errors errors={errors} />}
      <div className="form-inputs">
        <div className="row">
          <div className="col-md-6">
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="First Name"
              label="First Name"
              required={true}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder="Last Name"
              label="Last Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <PhoneInput
              type="text"
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                setCheckingPhone(false)
                handleChange(e)
              }}
              placeholder="Phone"
              label="Phone"
              required={true}
              onBlur={() => checkIfPhoneExists(formData.phone)}
              hint={checkingPhone}
            />
          </div>
          <div className="col-md-6">
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              label="Email"
            />
          </div>
        </div>
        <TextArea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          label="Message"
        />
        <Select
          label="Category"
          options={leadFormData?.categories?.map((category) => ({
            value: category,
            label: category,
          }))}
          onChange={(e) => setCategory(e ? e.value : null)}
          className="category-select"
          required={true}
        />
        <Select
          label="Source"
          className="source-select"
          options={leadFormData?.sources?.map((source) => ({
            value: source,
            label: source,
          }))}
          onChange={(e) => setSource(e ? e.value : null)}
          required={true}
        />
        {source === 'Website' && (
          <Select
            label="Website"
            options={leadFormData?.websites?.map((website) => ({
              value: website.id,
              label: website.name,
            }))}
            onChange={(e) => setWebsiteId(e ? e.value : null)}
          />
        )}
        <Select
          label="Location"
          options={leadFormData?.locations}
          onChange={(e) => setLocationId(e ? e.value : null)}
        />
        <Select
          label="Cars"
          options={leadFormData?.cars?.map((car) => ({
            value: car.id,
            label: car.name,
          }))}
          onChange={(e) => setCarId(e ? e.value : null)}
        />
        {(category === 'New Vehicle Enquiry' || category === 'Used Vehicle Enquiry') && (
          <div className="mb-3">
            <label>Test Drive Booking Date</label>
            <br />
            <Calendar
              id="test_drive_booking_date"
              onChange={(e) => setTestDriveBookingDate(e.value)}
              placeholder="Select a Date/Time"
              dateFormat="dd/mm/yy"
              showTime
              stepMinute={15}
              hourFormat="12"
              touchUI
            />
          </div>
        )}
        <div className="mb-3">
          <Switch
            id="lead_send_customer_email"
            label="Send Customer Email"
            value={sendCustomerEmail}
            onChange={() => setSendCustomerEmail(!sendCustomerEmail)}
          />
        </div>
        <div className="mb-3">
          <Switch
            id="lead_opt_in"
            label="Opt In to marketing"
            value={optIn}
            onChange={() => setOptIn(!optIn)}
          />
        </div>
      </div>
      <div className="form-actions">
        <button
          type="submit"
          id="submit-lead"
          className={loadingSubmit ? 'btn btn-primary disabled' : 'btn btn-primary'}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? (
            <>
              <i className="fa fa-spinner fa-spin mr-1" />
              Loading...
            </>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </form>
  )
}

export const FormWrapper = () => {
  const [opened, setOpened] = useState(false)
  return (
    <>
      <div
        className="btn btn-outline-primary w-100"
        onClick={() => setOpened(true)}
        id="add-lead-button"
      >
        <i className="fa fa-plus mr-1" />
        Lead
      </div>
      <Dialog
        header="New Lead"
        visible={opened}
        onHide={() => setOpened(false)}
        modal
        dismissableMask
        style={{ width: '800px' }}
      >
        <NewLeadForm opened={opened} />
      </Dialog>
    </>
  )
}

export default FormWrapper
