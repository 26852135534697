import Flatpickr from 'react-flatpickr'
import confirmDate from 'flatpickr/dist/plugins/confirmDate/confirmDate'
import 'flatpickr/dist/flatpickr.css'

const DateTimePicker = ({ value, onChange, id }) => {
  return (
    <Flatpickr
      data-enable-time
      id={id}
      value={value}
      onChange={onChange}
      options={{
        dateFormat: 'Y-m-d h:i K',
        plugins: [new confirmDate({})],
      }}
      className="form-control"
    />
  )
}

export default DateTimePicker
