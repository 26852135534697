import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

function stockPercentageClass(percent) {
  if (percent > 90) {
    return 'bg-success'
  }
  if (percent > 80) {
    return 'bg-warning'
  }
  return 'bg-light-red'
}

const HasPhotos = (rowData) => {
  return (
    <span>
      {rowData.cars_with_photos} ({rowData.percent_cars_with_photos}%)
    </span>
  )
}

const HasComments = (rowData) => {
  return (
    <span>
      {rowData.cars_with_comments} ({rowData.percent_cars_with_comments}%)
    </span>
  )
}

const CarHealthAnalytics = () => {
  let { dealershipSlug, dealershipGroupId } = useParams()
  let [data, setData] = useState([])
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    let url
    if (dealershipSlug) {
      url = `/dealerships/${dealershipSlug}/stock.json`
    } else {
      url = `/dealership_groups/${dealershipGroupId}/stock.json`
    }
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setLoading(false)
      })
  }, [dealershipSlug, dealershipGroupId])

  return (
    <>
      <div className="p-3 w-100">
        <div className="d-flex mb-2">
          <h4>Inventory Analytics</h4>
          <div className="ml-auto">
            <a
              href={`/dealerships/${dealershipSlug}/stock.csv`}
              className="btn btn-outline-primary"
            >
              Download CSV
            </a>
          </div>
        </div>
        <div className="box">
          <DataTable value={data} loading={loading}>
            <Column field="id" header="ID" sortable />
            <Column field="name" header="Name" sortable />
            <Column field="car_type" header="Car Type" sortable />
            <Column field="total_cars" header="Total Cars" sortable />
            <Column
              field="cars_with_photos"
              header="Has Photos"
              body={HasPhotos}
              bodyClassName={(rowData) => stockPercentageClass(rowData.percent_cars_with_photos)}
              sortable
            />
            <Column
              field="cars_with_comments"
              header="Has Comments"
              body={HasComments}
              bodyClassName={(rowData) => stockPercentageClass(rowData.percent_cars_with_comments)}
              sortable
            />
          </DataTable>
        </div>
      </div>
    </>
  )
}

export default CarHealthAnalytics
