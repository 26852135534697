import { createContext, useEffect, useState } from 'react'

export const LeadContext = createContext({})
export const LeadClusterContext = createContext({})
export const TestDriveContext = createContext({})
export const CarLoanContext = createContext({})
export const RootContext = createContext({})
export const OfferContext = createContext({})
export const PromotionContext = createContext({})
export const AppointmentContext = createContext({})
export const PhoneCallsContext = createContext({})
export const UnavailableIntervalContext = createContext({})
export const CarContext = createContext({})
export const PageContext = createContext({})
export const BlogContext = createContext({})
export const CurrentUserContext = createContext({})
export const DealershipContext = createContext({})
export const WebsiteContext = createContext({})
export const KanbanContext = createContext({})
export const ManufacturerContext = createContext({})

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const userId = window.current_user?.id
    if (userId) {
      fetch(`/users/me.json`)
        .then((response) => response.json())
        .then((data) => setCurrentUser(data))
        .catch((error) => console.error('Error fetching user data:', error))
    }
  }, [])

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>
}

export const DealershipProvider = ({ children }) => {
  const [dealership, setDealership] = useState(null)

  return (
    <DealershipContext.Provider value={{ dealership, setDealership }}>
      {children}
    </DealershipContext.Provider>
  )
}

export const WebsiteProvider = ({ children }) => {
  const [website, setWebsite] = useState(null)

  return (
    <WebsiteContext.Provider value={{ website, setWebsite }}>{children}</WebsiteContext.Provider>
  )
}

export const ManufacturerProvider = ({ children }) => {
  const [manufacturer, setManufacturer] = useState(null)

  return (
    <ManufacturerContext.Provider value={{ manufacturer, setManufacturer }}>
      {children}
    </ManufacturerContext.Provider>
  )
}

export const TestDriveProvider = ({ children }) => {
  const [testDrive, setTestDrive] = useState(null)

  return (
    <TestDriveContext.Provider value={{ testDrive, setTestDrive }}>
      {children}
    </TestDriveContext.Provider>
  )
}

export const CarLoanProvider = ({ children }) => {
  const [carLoan, setCarLoan] = useState(null)

  return (
    <CarLoanContext.Provider value={{ carLoan, setCarLoan }}>{children}</CarLoanContext.Provider>
  )
}
