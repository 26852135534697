import { useHits } from 'react-instantsearch'
import { truncateString, formatPhone } from '../entries/utils'
import { Link } from 'react-router-dom'

const Hit = ({ hit }) => {
  let url = `/dealerships/${dealer_slug}/contacts/${hit.id}`
  return (
    <tr>
      <td>
        <Link to={url}>
          {hit.first_name} {hit.last_name && truncateString(hit.last_name, 50)}
        </Link>
      </td>
      <td>
        <Link to={url}>{hit.email || <span className="text-secondary">Not Provided</span>}</Link>
      </td>
      <td>{hit.phone_number && formatPhone(hit.phone_number)}</td>
      <td>{hit.source}</td>
      <td>
        <Link to={url} className="btn btn-sm btn-outline-secondary mr-2">
          <i className="fa fa-eye" />
        </Link>
        <a
          href={`/dealerships/${dealer_slug}/contacts/${hit.id}/edit`}
          className="btn btn-sm btn-outline-secondary mr-2"
        >
          <i className="fa fa-edit" />
        </a>
        <a
          href={`/dealerships/${dealer_slug}/contacts/${hit.id}`}
          className="btn btn-sm btn-outline-secondary"
          data-method="delete"
        >
          <i className="fa fa-trash" />
        </a>
      </td>
    </tr>
  )
}

const Hits = () => {
  const { hits } = useHits()

  return (
    <div className="bg-white my-2">
      <div className="table-responsive">
        <table className="table table-bordered mb-0 rounded">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Source</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {hits.map((hit) => (
              <Hit hit={hit} key={hit.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Hits
