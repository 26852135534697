import React from 'react'
import { humanize } from '../entries/utils'

const ItemRole = (user) => {
  const role = user.role === 'manager'
  return (
    <div className={role ? 'text-success' : 'text-danger'} id={`status-${user.id}`}>
      {humanize(user.role)}
    </div>
  )
}

export default ItemRole
