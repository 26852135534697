import { Dialog } from 'primereact/dialog'
import { useState } from 'react'
import { useSaveConfig } from '../../../hooks'
import OverrideDefaultTimeRange from './OverrideDefaultTimeRange'
import FixedMode from './FixedMode'
import { useAnalyticsDashboard } from '../../../contexts/hooks'

const Settings = () => {
  const saveConfig = useSaveConfig()
  const { currentUser } = useAnalyticsDashboard()
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)

  const handleCloseDialog = async () => {
    setIsDialogVisible(false)
    try {
      await saveConfig()
    } catch (error) {
      console.error('Error saving config:', error)
    }
  }

  return (
    <>
      <div
        className="d-flex align-items-center mr-1 cursor-pointer btn-outline-primary settings-btn"
        style={{ border: '1px solid #3b7bff', borderRadius: '3px' }}
        onClick={() => setIsDialogVisible(true)}
      >
        <i className="fa-solid fa-gear fa-xl "></i>
      </div>
      <Dialog
        header={`Dashboard Settings`}
        visible={isDialogVisible}
        style={{ width: '70vw', minHeight: '40vw', textAlign: 'left' }}
        onHide={() => setIsDialogVisible(false)}
        footer={
          <button className={'btn btn-success'} onClick={() => handleCloseDialog()}>
            Save
          </button>
        }
        dismissableMask
      >
        <div className="d-flex align-items-center m-0 pb-2 row">
          <OverrideDefaultTimeRange />
          {currentUser?.admin ? <FixedMode /> : null}
        </div>
      </Dialog>
    </>
  )
}

export default Settings
