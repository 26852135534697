/**
 * Reusable utilities for working with forms using `react-hook-form` and
 * client-side data fetching. This is a minimalistic attempt.
 * See https://ui.shadcn.com/docs/components/form for a full-fledged example.
 **/
import type { DetailedHTMLProps, InputHTMLAttributes, SelectHTMLAttributes } from 'react'
import {
  Controller,
  type FieldValues,
  FormProvider,
  type RegisterOptions,
  type UseFormProps,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form'

type ValidationRules = Omit<
  RegisterOptions<FieldValues, string>,
  'disabled' | 'setValueAs' | 'valueAsNumber' | 'valueAsDate'
>

/**
 * Handle the response from a `fetch` and forcefully throw an appropriate error
 * if the response has an issue
 **/
export async function handleResponse<T = unknown>(res: Response): Promise<T> {
  if (!res.ok) {
    const json = await res.json()
    if (json.error) {
      const error = new Error(json.error) as Error & {
        status: number
      }
      error.status = res.status
      throw error
    } else {
      throw new Error(`An unexpected error occurred${res?.statusText ? `: ${res.statusText}` : ''}`)
    }
  }

  if (res.status === 204) {
    // avoid throwing an error for NO CONTENT delete actions
    return res.statusText as T
  }
  return res.json()
}

/**
 * A simple, unopinionated provider for forms intended to make it easy
 * to use `react-hook-form` for managing form state.
 */
export function Form<TFormFields extends object>({
  children,
  onSubmit,
  className,
  ...formProps
}: {
  children: React.ReactNode
  onSubmit: (data: TFormFields) => Promise<void>
  className?: string
} & UseFormProps<TFormFields>) {
  const form = useForm({ ...formProps })
  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          await onSubmit(data)
        })}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  )
}

/**
 * A form input which uses `react-hook-form` for managing its state.
 * Simply pass `name` and `label` to use this input, and optionally pass
 * any other props you would pass to `<input />`
 */
export function FormInput({
  name,
  label,
  rules,
  ...inputProps
}: { label: string; rules?: ValidationRules } & DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div>
          <div className="form-group">
            <label htmlFor={name}>
              {label}
              {inputProps?.required && <span className="text-danger">*</span>}
            </label>
            <input
              {...inputProps}
              {...field}
              placeholder={inputProps?.placeholder ?? label}
              disabled={isSubmitting}
              className="form-control"
              value={field.value ?? ''}
            />
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}

/**
 * A select input which uses `react-hook-form` for managing its state.
 * Simply pass `name`, `label` and `options` to use this input, and optionally pass
 * any other props you would pass to `<select />`
 */
export function FormSelect({
  name,
  label,
  options,
  rules,
  formatOption = (val: string) => val,
  ...selectProps
}: {
  label: string
  options: string[]
  rules?: ValidationRules
  formatOption?: (val: string) => string
} & DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) {
  const { control } = useFormContext()
  const { errors, isSubmitting } = useFormState()
  const errorMessage = errors[name]?.message ?? null

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => (
        <div>
          <div className="form-group">
            <label htmlFor={name}>
              {label}
              {selectProps?.required && <span className="text-danger">*</span>}
            </label>
            <select
              {...selectProps}
              {...field}
              disabled={isSubmitting}
              className="form-control"
              value={field.value ?? ''}
            >
              <option value="" disabled>
                Select a form type
              </option>
              {options.map((option, i) => (
                <option value={option} key={`option-${i}`}>
                  {formatOption(option)}
                </option>
              ))}
            </select>
          </div>
          {errorMessage && <p className="text-danger">{String(errorMessage)}</p>}
        </div>
      )}
    />
  )
}
