import React from 'react'
import ModulePicker from '../features/EditMode/components/ModulePicker'
import Settings from '../features/DashboardSettings/components/Settings'
import SelectLocations from '../features/DashboardSettings/components/SelectLocations'
import TimeRangePicker from '../../entries/TimeRange'
import { useAnalyticsDashboard, useConfig, useItems, useTimeRangeLocation } from '../contexts/hooks'
import EditMode from '../features/EditMode/components/EditMode'
import LayoutSizeComponent from '../features/DashboardSettings/components/LayoutSizeComponent'
import { getWebsite } from '../utils'
import * as Routes from '../../../routes'
import { useNameBasedOnLevel } from '../hooks'

const Header: React.FC = () => {
  const { editModeEnabled, currentUser, dashboardLevel, dealership } = useAnalyticsDashboard()
  const { items } = useItems()
  const { config } = useConfig()
  const { handleUpdateTimeRange, handleUpdateSelectedLocation } = useTimeRangeLocation()

  // Get the website data if the dashboardLevel is Website, for the GA Profile check
  const website = dashboardLevel === 'Website' ? getWebsite() : null
  const gaProfileExists = website?.gaProfileExists ?? false
  const hasCampaigns = website?.hasCampaigns ?? false
  const name = useNameBasedOnLevel()

  // Conditional rendering variables
  const isManufacturer: boolean = dashboardLevel === 'Manufacturer'
  const showName: boolean = name !== undefined && !isManufacturer
  const showSettings: boolean = currentUser?.admin || !config?.fixedMode

  const addGAProfile = (): void => {
    const dealershipId = website?.dealershipId ?? dealership?.id
    window.open(Routes.fetch_access_token_dealership_ga_profiles_path(dealershipId), '_blank')
  }
  return (
    <>
      <div className="row">
        {!showName ? <h3 className="col-6 p-0 pl-3">Analytics Dashboard</h3> : null}
        {showName ? <h4 className="col-6 pl-3">{name}</h4> : null}
        {editModeEnabled && isManufacturer ? (
          <div className="ml-auto">
            <ModulePicker items={items} />
          </div>
        ) : null}
        <div className="col-6 d-flex pl-0">
          <div className="ml-auto d-flex">
            {showSettings ? <Settings /> : null}
            {currentUser ? (
              <SelectLocations
                handleUpdateSelectedLocation={handleUpdateSelectedLocation}
                currentUser={currentUser}
              />
            ) : null}
            <TimeRangePicker handleUpdateTimeRange={handleUpdateTimeRange} />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center py-2">
        {!isManufacturer ? <h3 className="col-6 p-0">Analytics Dashboard</h3> : null}
        <div className={`col-${isManufacturer ? '12' : '6'} p-0 d-flex justify-content-end`}>
          {editModeEnabled ? <LayoutSizeComponent /> : null}
          <EditMode
            addGAProfile={addGAProfile}
            gaProfileExists={gaProfileExists}
            hasCampaigns={hasCampaigns}
          />
          {editModeEnabled && !isManufacturer ? <ModulePicker items={items} /> : null}
        </div>
      </div>
    </>
  )
}

export default Header
