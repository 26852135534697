import * as Routes from '../../routes'
export const UserService = {
  getUsers(params, csrf, dealershipId) {
    let userRoute = ''
    if (dealershipId === null) {
      userRoute = Routes.users_path()
    } else {
      userRoute = Routes.dealership_users_path(dealershipId)
    }

    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''

    return fetch(`${userRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    }).then((res) => res.json())
  },
}
