import { useState, useContext } from 'react'
import { LeadContext } from '../contexts'
const GenerateAiReply = ({ aiReply, setAiReply }) => {
  const { lead } = useContext(LeadContext) || {}

  let [loading, setLoading] = useState(false)
  let [error, setError] = useState(false)

  let csrfToken = document.querySelector('[name="csrf-token"]')?.content

  const generateAiResponse = () => {
    if (aiReply) return

    setLoading(true)
    fetch(`/dealerships/${lead.dealership_id}/leads/${lead.id}/reply_with_ai`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error)
        } else {
          setAiReply(data.message)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  return (
    <div className="mb-2">
      <div
        className={'btn btn-outline-secondary btn-sm' + (aiReply ? ' disabled' : '')}
        onClick={generateAiResponse}
        disabled={aiReply}
      >
        {loading ? (
          <>
            <i className="fa fa-spinner fa-spin mr-2"></i>
            Loading...
          </>
        ) : (
          <>
            Write AI Response
            <i className="fa fa-rocket ml-1"></i>
          </>
        )}
      </div>
      {error && <div className="text-danger">{error}</div>}
    </div>
  )
}

export default GenerateAiReply
