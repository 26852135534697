import { useEffect, useState } from 'react'
import { DndContext, rectIntersection } from '@dnd-kit/core'
import KanbanLane from './KanbanLane'
import { updateLeadState } from '../../leads/utils'
import ScrollBars from './ScrollBars'
import { KanbanContext } from '../../contexts'

const KanbanBoard = ({ leads, columns, refreshData }) => {
  let [tasks, setTasks] = useState(leads)

  useEffect(() => {
    setTasks(leads)
  }, [leads])

  const updateLead = async ({ leadId, leadStatus }) => {
    await updateLeadState(leadId, leadStatus)
    await refreshData()
  }

  const onDragEnd = (e) => {
    const container = e.over?.id

    if (container !== undefined) {
      let newTasks = tasks.map((task) => {
        if (task.id === e.active.data.current.id) {
          return { ...task, column: container, loading: true }
        } else {
          return task
        }
      })
      setTasks(newTasks)
    }

    updateLead({ leadId: e.active.data.current.id, leadStatus: container })
  }

  const onDragOver = (e) => {
    const container = e.over?.id

    if (container !== undefined) {
      let newTasks = tasks.map((task) => {
        if (task.id === e.active.data.current.id) {
          return { ...task, column: container }
        } else {
          return task
        }
      })
      setTasks(newTasks)
    }
  }

  return (
    <KanbanContext.Provider value={{ refreshData }}>
      <DndContext
        collisionDetection={rectIntersection}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <ScrollBars>
          {columns.map(({ title, colour_class_name }, key) => (
            <KanbanLane
              title={title}
              colour_class_name={colour_class_name}
              items={tasks.filter((task) => task.column === title)}
              key={key}
            />
          ))}
        </ScrollBars>
      </DndContext>
    </KanbanContext.Provider>
  )
}

export default KanbanBoard
