export const attributes = {
  vin: {
    label: 'VIN',
    lockable: true,
    type: 'text',
  },
  regplate: {
    label: 'Rego',
    lockable: false,
    type: 'text',
  },
  stocknum: {
    label: 'Stock Number',
    lockable: false,
    type: 'text',
  },
  dapprice: {
    label: 'DAP Price',
    lockable: true,
    type: 'currency',
  },
  egcprice: {
    label: 'EGC Price',
    lockable: true,
    type: 'currency',
  },
  before_price: {
    label: 'Before Price (was/now pricing)',
    lockable: true,
    type: 'currency',
  },
  subscription_price: {
    label: 'Subscription Price (per week)',
    lockable: true,
    type: 'currency',
  },
  daily_hire_price: {
    label: 'Daily Hire Price',
    lockable: true,
    type: 'currency',
  },
  car_type: {
    label: 'Car Type',
    options: 'car_type_options',
    lockable: false,
    type: 'select',
    lockable: true,
  },
  colour: {
    label: 'Colour',
    options: 'colour_options',
    lockable: false,
    type: 'select',
  },
  interior_colour: {
    label: 'Interior Colour',
    options: 'colour_options',
    lockable: false,
    type: 'select',
  },
  rego_state: {
    label: 'Rego State',
    options: 'state_options',
    lockable: false,
    type: 'select',
  },
  rego_expiry: {
    label: 'Rego Expiry',
    lockable: false,
    type: 'date',
  },
  build_date: {
    label: 'Build Date',
    lockable: false,
    type: 'date',
  },
  compliance_date: {
    label: 'Compliance Date',
    lockable: false,
    type: 'date',
  },
  status: {
    label: 'Status',
    options: 'status_options',
    lockable: false,
    type: 'select',
  },
  km: {
    label: 'Odometer',
    lockable: true,
    type: 'number',
  },
  trans: {
    label: 'Transmission',
    options: 'transmission_options',
    lockable: false,
    type: 'select',
  },
  dealercomments: {
    label: 'Comments',
    lockable: false,
    type: 'textarea',
  },
}
