import { useEffect } from 'react'
import DragAndDrop from '../features/DragAndDrop/components/DragAndDrop'

import { getWebsite } from '../utils'
import { automateCampaignDeletion, automateAddCampaigns } from '../api'
import Tabs from '../features/Tabs/components/Tabs'
import { useParams } from 'react-router-dom'
import { useAnalyticsDashboard, useItems, usePreviewData, useTabs } from '../contexts/hooks'
import Header from './Header'
import { useSetModuleLayouts } from '../hooks'
import Loading from '../../Loading'

const AnalyticsDashboard: React.FC = () => {
  const {
    dashboardLevel,
    dashboardLevelLoaded,
    canView,
    editModeEnabled,
    dealership,
    analyticsBlock,
    analyticsBlockLoading,
  } = useAnalyticsDashboard()
  const { selectedTab, tabLayouts, tabTitles } = useTabs()
  const { handleUpdateItems } = useItems()
  const { setEditingPreviewData } = usePreviewData()
  const params = useParams()
  const setModuleLayouts = useSetModuleLayouts()

  const dealershipSlug =
    params.dealershipSlug || dealership?.slug || getWebsite()?.dealershipId.toString()

  const multipleTabs: boolean = Object.keys(tabLayouts).length > 1 && tabTitles.length > 1

  useEffect(() => {
    if (tabLayouts && selectedTab in tabLayouts) {
      handleUpdateItems(tabLayouts[selectedTab])
    }
  }, [tabLayouts, selectedTab])

  // useEffect that runs when the component mounts
  useEffect(() => {
    // If there is no data in the block, set the current items to the block
    const blockData = analyticsBlock?.data
    if (blockData && Object.keys(blockData).length === 0 && blockData.constructor === Object) {
      setModuleLayouts()
    }
    if (dealershipSlug) {
      automateCampaignDeletion(dealershipSlug)
      if (dashboardLevel !== 'Manufacturer') {
        automateAddCampaigns(dealershipSlug)
      }
    }
  }, [])

  // Set preview data to null when exiting edit mode
  useEffect(() => {
    if (!editModeEnabled) {
      setEditingPreviewData(null)
    }
  }, [editModeEnabled])

  if (!canView) {
    return null
  }

  return (
    <>
      {dashboardLevelLoaded && !analyticsBlockLoading ? (
        <div className="p-4">
          <Header />
          {multipleTabs || editModeEnabled ? (
            <div className="d-flex align-items-center py-2">
              <Tabs />
            </div>
          ) : null}
          <DragAndDrop />
        </div>
      ) : (
        <div className="justify-content-center d-flex mt-5">
          <Loading />
        </div>
      )}
    </>
  )
}

export default AnalyticsDashboard
