import AnalyticsDashboard from './AnalyticsDashboard'
import ContextWrapper from '../contexts/ContextWrapper'

const AnalyticsDashboardApp = () => {
  return (
    <ContextWrapper>
      <AnalyticsDashboard />
    </ContextWrapper>
  )
}

export default AnalyticsDashboardApp
