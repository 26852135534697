import { useState, useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import SortPhotos from '../cars/SortPhotos'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom'

const CarName = (car) => {
  return <Link to={`/cars/${car.id}`}>{car.name}</Link>
}

let StockImageSet = ({ stockImageSet, stockImageSets, setStockImageSets }) => {
  let [data, setData] = useState()
  let [photos, setPhotos] = useState([])
  let [updatedPhotos, setUpdatedPhotos] = useState([])
  let [loading, setLoading] = useState(true)
  let [loadingSubmit, setLoadingSubmit] = useState(false)
  let [visible, setVisible] = useState(false)

  let { id, family, badge } = stockImageSet

  const sendPhotosToServer = () => {
    if (updatedPhotos !== photos) {
      setLoadingSubmit(true)
      fetch(`/stock_image_sets/${id}/photos/sort`, {
        method: 'PATCH',
        body: JSON.stringify({
          photo: updatedPhotos.map((item) => item.id),
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setStockImageSets({
            ...stockImageSets,
            stock_image_sets: stockImageSets.stock_image_sets.map((item) => {
              if (item.id === id) {
                return { ...item, primary_photo_url: updatedPhotos[0].image.url }
              }
              return item
            }),
          })
          setLoadingSubmit(false)
          setVisible(false)
        })
    }
  }

  useEffect(() => {
    if (!visible) {
      return
    }
    setLoading(true)
    fetch(`/stock_image_sets/${id}.json`)
      .then((res) => res.json())
      .then((data) => {
        setData(data)
        setPhotos(data.photos)
        setLoading(false)
      })
  }, [visible])

  return (
    <>
      <Dialog
        header={`${family?.name} ${badge}`}
        visible={visible}
        style={{ width: '50vw' }}
        dismissableMask
        onHide={() => setVisible(false)}
      >
        {loading ? (
          <div>Loading...</div>
        ) : (
          <SortPhotos photos={photos} setUpdatedPhotos={setUpdatedPhotos} />
        )}
        <button
          type="button"
          className={'btn btn-primary' + (loadingSubmit ? ' disabled' : '')}
          onClick={sendPhotosToServer}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? 'Loading...' : 'Save changes'}
        </button>

        {data?.cars && (
          <div className="mt-3">
            <p>Cars using this set: </p>
            <DataTable value={data.cars}>
              <Column field="id" header="ID" />
              <Column field="name" header="Name" body={CarName} />
              <Column field="dealership.name" header="Dealership" />
            </DataTable>
          </div>
        )}
      </Dialog>
      <div className="dropdown-item" onClick={() => setVisible(true)}>
        <i className="fa fa-eye mr-2"></i>
        Show
      </div>
    </>
  )
}

export default StockImageSet
