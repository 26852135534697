import React, { useState, useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import Note from './Note'
import showToast from '../shared/ShowToast'

const transitionStyles = {
  enter: {
    opacity: 0.01,
  },
  enterActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-in',
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0.01,
    transition: 'opacity 300ms ease-in',
  },
}

function NotesList({ initialNotes, notesPath, currentUserId, isAdmin }) {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content')
  const [notes, setNotes] = useState(initialNotes)
  const [loading, setLoading] = useState(false)
  const [noteContent, setNoteContent] = useState('')
  const notification = useRef(null)

  useEffect(() => {
    if (notesPath) {
      refreshNotes()
    }
  }, [])

  const handleDeleteClick = async (noteId) => {
    setLoading(true)
    fetch(notesPath + `/${noteId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
        Accept: 'application/json',
      },
    })
      .then((res) => {
        if (res.status === 204) {
          return null // No content to parse as JSON
        } else {
          return res.json() // Parse the response body as JSON
        }
      })
      .then((data) => {
        setLoading(false)
        refreshNotes() // Refresh the notes list after deletion
        showToast(notification, 'success', 'Note deleted!', 'The note was successfully deleted.')
      })
      .catch((error) => {
        setLoading(false)
        showToast(notification, 'error', 'Error deleting this note!', error)
        console.error('There was an error deleting the note:', error)
      })
  }

  const handleNoteUpdate = async (noteId, content) => {
    if (content.trim() === '') return // Prevent adding empty notes

    const formData = new FormData()
    formData.append('note[content]', content)

    try {
      setLoading(true)
      const response = await fetch(notesPath + `/${noteId}`, {
        method: 'PUT',
        'data-method': 'PUT',
        headers: {
          'X-CSRF-Token': csrf,
          Accept: 'application/json',
        },
        body: formData,
      })

      setLoading(false)

      if (!response.ok) {
        const errors = await response.json()
        showToast(notification, 'error', 'Error updating this note!', errors.message)
        throw errors
      } else {
        showToast(notification, 'success', 'Note updated!', 'The note was successfully updated.')
        refreshNotes()
      }
    } catch (errors) {
      throw errors
    }

    setNoteContent('')
  }

  const handleNewNoteSubmit = async () => {
    if (noteContent.trim() === '') return // Prevent adding empty notes

    const formData = new FormData()
    formData.append('note[content]', noteContent)

    try {
      setLoading(true)
      const response = await fetch(notesPath, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrf,
          Accept: 'application/json',
        },
        body: formData,
      })

      setLoading(false)

      if (!response.ok) {
        const errors = await response.json()
        showToast(notification, 'error', 'Error creating this note!', errors.message)
        throw errors
      } else {
        showToast(notification, 'success', 'Note created!', 'The note was successfully created.')
        refreshNotes()
      }
    } catch (errors) {
      throw errors
    }

    setNoteContent('')
  }

  const refreshNotes = async () => {
    setLoading(true)
    fetch(notesPath + '.json', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setNotes(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching notes:', error)
      })
  }

  return (
    <div>
      <Toast ref={notification} />
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <div>
            <strong>New Note</strong>
          </div>
          <div>
            {loading ? (
              <>Loading...</>
            ) : (
              <button className="btn btn-primary" onClick={handleNewNoteSubmit}>
                Add Note
              </button>
            )}
          </div>
        </div>
        <div className="note-form card-body">
          <textarea
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="Enter your note here..."
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <TransitionGroup>
        {notes.map((note) => (
          <CSSTransition
            key={note.id}
            timeout={{ enter: 500, exit: 300 }}
            // Add this prop to apply the inline styles
            onEnter={(node) => Object.assign(node.style, transitionStyles.enter)}
            onEntering={(node) => Object.assign(node.style, transitionStyles.enterActive)}
            onExit={(node) => Object.assign(node.style, transitionStyles.exit)}
            onExiting={(node) => Object.assign(node.style, transitionStyles.exitActive)}
          >
            <Note
              note={note}
              onDeleteClick={() => handleDeleteClick(note.id)}
              onSubmit={handleNoteUpdate}
              currentUserId={currentUserId}
              isAdmin={isAdmin}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </div>
  )
}

export default NotesList
