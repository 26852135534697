import * as Routes from '../../routes'
export const AppointmentService = {
  async getAppointments(params, csrf, dealershipId) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let pagesRoute = ''

    pagesRoute = Routes.dealership_appointments_path(dealershipId)

    const res = await fetch(`${pagesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    })
    return await res.json()
  },

  async checkingOverlapingUnavailableIntervals(params, csrf, dealershipId) {
    const queryParams = params
      ? Object.keys(params)
          .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
          .join('&')
      : ''
    let pagesRoute = ''

    pagesRoute = Routes.check_existing_dealership_users_unavailable_intervals_path(dealershipId)

    const res = await fetch(`${pagesRoute}.json?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf,
      },
      credentials: 'same-origin',
    })
    return await res.json()
  },
}
