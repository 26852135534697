const Modal = ({ header, children, id, modalFooter, width, onClose }) => (
  <div
    className="modal fade"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    id={id}
  >
    <div
      className={`modal-dialog modal-${width ? width : 'lg'} modal-dialog-centered`}
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {header}
          </h5>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={onClose ? onClose : () => $(`#${id}`).modal('hide')}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        {modalFooter && <div className="modal-footer">{modalFooter}</div>}
      </div>
    </div>
  </div>
)

export default Modal
