import React from 'react'

export const StarRating = ({ stars }) => {
  if (!stars || stars.length === 0) return <div></div>
  return (
    <div className={'d-flex align-items-center'}>
      {[...Array(Math.round(stars))].map((_, index) => (
        <i className="fa fa-star" style={{ color: '#ffb542' }} key={`star-rating-${index}`} />
      ))}
    </div>
  )
}
