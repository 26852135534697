// MANAGED BY App.js
import { useEffect, useState, useContext } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import LeadStatsTooltip from './LeadStatsTooltip'
import ExportDestination from './ExportDestination'
import CarHealth from './CarHealth'
import DataTableSearch from '../entries/DataTableSearch'
import { FilterMatchMode } from 'primereact/api'
import Loading from '../Loading'
import { useFetchDealership } from '../dataHooks'

export const Image = (car) => {
  return (
    <img
      src={car.primary_image_url}
      alt={car.name}
      height={50}
      className="rounded"
      style={{ objectFit: 'cover' }}
    />
  )
}

const AutograbIndex = () => {
  let [loading, setLoading] = useState(false)
  let [cars, setCars] = useState(false)
  const { dealership } = useFetchDealership()

  useEffect(() => {
    if (!cars && dealership?.slug) {
      setLoading(true)
      fetch(`/dealerships/${dealership.slug}/cars/autograb.json`)
        .then((response) => response.json())
        .then((data) => {
          setCars(data)
          setLoading(false)
        })
    }
  }, [dealership?.slug])

  const formatCurrency = (value) => {
    if (value === 0 || value === null || value === undefined) {
      return ''
    }

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  const AverageMarketPrice = (car) => formatCurrency(car.autograb_data.average_market_price)
  const Price = (car) => formatCurrency(car.price)
  const StartPrice = (car) => formatCurrency(car.start_price)

  const Km = (car) => {
    return <span>{car.km.toLocaleString()} km</span>
  }

  const AverageKm = (car) => {
    if (car.autograb_data.average_market_kms === null) {
      return <></>
    }

    return <span>{car.autograb_data.average_market_kms.toLocaleString()} km</span>
  }

  const Name = (car) => {
    return <a href={`/cars/${car.slug}`}>{car.name}</a>
  }

  const Age = (car) => {
    return <div>{car.days_old} days</div>
  }

  const LastPriceChange = (car) => {
    if (car.days_since_last_price_change === null || car.price === 0) {
      return <></>
    }

    if (car.days_since_last_price_change === 0) {
      return <div>Today</div>
    }

    return <div>{car.days_since_last_price_change} days</div>
  }

  const DaysSupply = (car) => {
    if (car.autograb_data.market_days_supply === null) {
      return <></>
    }

    return <div>{car.autograb_data.market_days_supply} days</div>
  }

  const LeadsCount = (car) => {
    let className = ''
    if (car.leads_count > 0) {
      className = 'text-success'
    }

    return (
      <div className={className} id={`leads-count-${car.id}`}>
        {car.leads_count} leads
        {car.leads_count > 0 && <LeadStatsTooltip hit={car} />}
      </div>
    )
  }

  const PriceRank = (car) => {
    if (car.autograb_data.price_rank === null || car.autograb_data.price_rank === 0) {
      return <></>
    }

    return (
      <div>
        {car.autograb_data.price_rank}/{car.autograb_data.sample_size}
      </div>
    )
  }

  const AutogateSwitch = (car) => {
    return (
      <ExportDestination
        car={car}
        destination="Autogate"
        fieldName="load_to_autogate"
        setCar={() => {}}
      />
    )
  }

  const AutotraderSwitch = (car) => {
    return (
      <ExportDestination
        car={car}
        destination="Autotrader"
        fieldName="load_to_autotrader"
        setCar={() => {}}
      />
    )
  }

  const ItemHealth = (car) => (
    <div className="text-center">
      <CarHealth hit={car} />
    </div>
  )

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })

  const header = DataTableSearch({ filters: filters, setFilters: setFilters })

  return (
    <div className="p-3">
      <h3>Inventory Pricing</h3>

      <div className="box w-100">
        {loading && (
          <div className="p-3">
            <Loading />
          </div>
        )}

        {cars && (
          <DataTable
            value={cars}
            tableStyle={{ minWidth: '50rem' }}
            removableSort
            sortMode="multiple"
            scrollable
            className="small"
            paginator
            rows={50}
            globalFilterFields={['name', 'stocknum', 'location_name', 'rego']}
            filters={filters}
            header={header}
          >
            <Column body={Image} field="image" header="Image"></Column>
            <Column sortable body={Name} field="name" header="Name"></Column>
            <Column sortable field="stocknum" header="Stock No"></Column>
            <Column sortable field="location_name" header="Location Name"></Column>
            <Column sortable field="rego" header="Rego"></Column>
            <Column sortable body={Km} field="km" header="Odo"></Column>
            <Column sortable body={StartPrice} field="start_price" header="Starting"></Column>
            <Column sortable body={Price} field="price" header="Latest Price"></Column>
            <Column sortable field="price_changes_count" header="Drops"></Column>
            <Column sortable body={Age} field="days_old" header="Age"></Column>
            <Column sortable field="days_to_twenty_photos" header="Days to 20 photos"></Column>
            <Column
              sortable
              body={LastPriceChange}
              field="days_since_last_price_change"
              header="Price Updated"
            ></Column>
            <Column sortable body={LeadsCount} field="leads_count" header="Leads Count"></Column>
            <Column body={ItemHealth} header="Car Health"></Column>
            <Column
              sortable
              body={PriceRank}
              field="autograb_data.price_rank"
              header="Price Rank"
            ></Column>
            <Column
              sortable
              body={DaysSupply}
              field="autograb_data.market_days_supply"
              header="Supply"
            ></Column>
            <Column
              sortable
              body={AverageMarketPrice}
              field="autograb_data.average_market_price"
              header="Avg. Price"
            ></Column>
            <Column
              sortable
              body={AverageKm}
              field="autograb_data.average_market_kms"
              header="Avg. Kms"
            ></Column>
            <Column body={AutogateSwitch} field="load_to_autogate" header="Autogate"></Column>
            <Column body={AutotraderSwitch} field="load_to_autotrader" header="Autotrader"></Column>
          </DataTable>
        )}
      </div>
    </div>
  )
}

export default AutograbIndex
