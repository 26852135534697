import React from 'react'

const Loading = () => (
  <div className="d-flex">
    <div className="loading-spinner"></div>
    <p className="text-secondary">Loading...</p>
  </div>
)

export default Loading
