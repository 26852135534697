import { SelectFetch } from 'react-select-fetch'
import * as Routes from '../../routes'

export const ContactSelector = ({ dealership, selectedContact, setSelectedContact }) => {
  return (
    <div className="col-md-6">
      <div id="select-contact" className="card mb-4">
        <div className="card-header">Select a Customer</div>
        <div className="card-body">
          <SelectFetch
            value={selectedContact}
            url={Routes.dealership_contacts_path(dealership.id) + '.json'}
            mapResponse={(response: any) => {
              return {
                options: response.map((contact: { id: string; name: string }) => ({
                  value: contact.id,
                  label: contact.name,
                })),
                hasMore: false,
              }
            }}
            onChange={setSelectedContact}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactSelector
