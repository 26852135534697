import { useState, useEffect } from 'react'
import { standardHeaders } from '../entries/utils'
import { useParams } from 'react-router-dom'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dialog } from 'primereact/dialog'

const Settings = ({ websiteSettings }) => {
  let [visible, setVisible] = useState(false)

  return (
    <div className="ml-auto pr-3 pt-2">
      <button onClick={() => setVisible(true)} className="btn btn-outline-primary">
        Settings
      </button>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        dismissableMask
        style={{ width: '50vw' }}
        header="SEO Settings"
      >
        <div className="p-3">
          <h6>Location Make Search Title:</h6>
          <pre>{websiteSettings.location_make_search_title}</pre>
        </div>
      </Dialog>
    </div>
  )
}

const SearchPages = () => {
  let { websiteSlug } = useParams()
  let [pages, setPages] = useState([])
  let [websiteSettings, setWebsiteSettings] = useState({})
  let [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    fetch(`/websites/${websiteSlug}/search_pages`, {
      method: 'GET',
      headers: standardHeaders,
    })
      .then((res) => res.json())
      .then((data) => {
        setPages(data.pages)
        setWebsiteSettings(data.settings)
        setLoading(false)
      })
  }, [])

  return (
    <div className="m-3 box">
      <div className="d-flex pt-2">
        <h5 className="p-3">Search Pages</h5>
        <Settings websiteSettings={websiteSettings} />
      </div>

      <DataTable value={pages} loading={loading}>
        <Column field="title" header="Title" />
        <Column field="path" header="URL" />
      </DataTable>
    </div>
  )
}

export default SearchPages
