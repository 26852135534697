import { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip'
import { format } from 'date-fns'
import GenerateAiReply from '../leads/GenerateAiReply'
import { formatPhone } from '../entries/utils'
import { standardHeaders } from '../entries/utils'
import { ownerContext } from '../leads/utils'
import Templates from './sms/Templates'

const Message = ({ text }) => {
  const formattedDate = format(new Date(text.created_at), 'dd/MM/yyyy HH:mm')

  return (
    <>
      <p
        className={(text.direction == 'inbound' ? 'from-them' : 'from-me') + ` ${text.status}`}
        id={`text-${text.id}`}
      >
        <span>{text.text}</span>
      </p>
      <Tooltip anchorSelect={`#text-${text.id}`} place="bottom" style={{ zIndex: 10000 }}>
        <small>{formattedDate}</small>
        <div className="small">{text.status}</div>
        {(text.user || text.ai_generated) && (
          <div className="small">
            Sent By: {text.ai_generated ? 'AI Generated' : text.user?.name}
          </div>
        )}
      </Tooltip>
    </>
  )
}

const defaultPhone = '0498025203'

const SmsConversation = () => {
  const { owner, setOwner } = ownerContext()
  let [message, setMessage] = useState('')
  let [texts, setTexts] = useState(owner.texts)
  let [loading, setLoading] = useState(false)
  let [aiReply, setAiReply] = useState('')

  useEffect(() => {
    if (aiReply) {
      setMessage(aiReply)
    }
  }, [aiReply])

  useEffect(() => {
    setOwner({ ...owner, texts: texts })
  }, [texts])

  const onSubmit = () => {
    setLoading(true)
    fetch(`/api/dealership/${owner.dealership_id}/texts`, {
      method: 'POST',
      headers: standardHeaders,
      body: JSON.stringify({
        content: message,
        message_type: 'sms',
        phone: owner.contact.phone_number,
        from: owner.dealership?.sms_phone_number,
        contact_id: owner.contact_id,
        service: owner.dealership?.sms_phone_number === defaultPhone ? 'clicksend' : 'plivo',
        user_id: window.current_user.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setTexts([data.text, ...texts])
        setMessage('')
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setLoading(false)
      })
  }

  if (owner.message == null || owner.message == '') {
    const openingMessage = {
      id: 'openingMessage',
      text: `${owner.contact.first_name} submitted a ${owner.category || 'lead'}`,
      direction: 'inbound',
      created_at: owner.created_at,
      status: 'created',
    }

    const messageExists = texts.some((text) => text.id === openingMessage.id)

    if (!messageExists) {
      texts.push(openingMessage)
    }
  }

  return (
    <div className="mx-3 mt-2 mb-2">
      <div className="border rounded mb-2">
        <div className="imessage mb-0">
          {texts
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map((text) => (
              <Message key={text.id} text={text} />
            ))}
        </div>
      </div>
      {owner.accepted_category_for_ai && (
        <GenerateAiReply aiReply={aiReply} setAiReply={setAiReply} />
      )}
      <div className="border rounded bg-whit message-form d-flex">
        <textarea
          placeholder="Type Your Message..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          maxLength="459"
        />
        <button disabled={message === '' || loading} onClick={onSubmit}>
          {loading ? 'Loading...' : 'Send'}
        </button>
      </div>
      <Templates setMessage={setMessage} />
      <div className="text-center border rounded mt-2 p-2">
        {owner.dealership?.sms_phone_number === defaultPhone ? (
          <small className="text-danger mt-2">
            <i className="fas fa-exclamation-triangle"></i> WARNING: SMS will be sent from{' '}
            <b>{formatPhone(owner.dealership?.sms_phone_number)}</b>. This is a shared number. If
            you would like a dedicated mobile number, please contact support.
          </small>
        ) : (
          <small className="text-secondary mt-2">
            SMS will be sent from your dedicated number:{' '}
            <b>{formatPhone(owner.dealership?.sms_phone_number)}</b>.
          </small>
        )}
      </div>
    </div>
  )
}

export default SmsConversation
