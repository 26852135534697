import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom'
import * as ReactDOM from 'react-dom/client'
import AdminBilling from './admin/Billing'
import AdminCarsalesAccounts from './admin/CarsalesAccounts'
import AdminPushNotifications from './admin/PushNotifications'
import AdminVehicleDetailLookups from './admin/VehicleDetailLookups'
import AdminDealershipPhoneCallsCost from './admin/DealershipPhoneCallsCost'
import AnalyticsDashboardApp from './AnalyticsDashboard/components/AnalyticsDashboardApp'
import AnalyticsSocialAdAccounts from './AnalyticsDashboard/features/SocialAdAccounts/components/SocialAdAccounts'
import AppointmentsCalendar from './appointments/Calendar'
import CarsAutograbIndex from './cars/AutograbIndex'
import CarHealthAnalytics from './cars/CarHealthAnalytics'
import CarLoanForm from './carLoans/CarLoanForm'
import CarLoansSearch from './carLoans/Search'
import CarsEditRedbookDetails from './cars/EditRedbookDetails'
import CarsRedbook from './cars/redbook'
import CarsReports from './cars/Reports'
import CarsSearch from './cars/Search'
import CarShow from './cars/show'
import CarsSmartPublisherSettings from './cars/SmartPublisherSettings'
import CarsStockRestrictions from './cars/StockRestrictions'
import ContactsSearch from './contacts/searchContacts'
import ContactShow from './contacts/ShowContact'
import {
  RootContext,
  CarLoanProvider,
  CurrentUserProvider,
  DealershipProvider,
  WebsiteProvider,
  TestDriveProvider,
  ManufacturerProvider,
} from './contexts'
import DealershipsCatalogues from './dealerships/Catalogues'
import DealershipsIndex from './dealerships/Index'
import DealershipUsersUnavailableIntervals from './unavailableIntervals/dealershipUsersUnavailableIntervals'
import EnquiryAddons from './enquiryAddons/EnquiryAddons'
import Error from './shared/Error'
import FeedsIndex from './feeds/index'
import IndexPage from './Home/Index'
import LeadClusterShowLite from './leadCluster/ShowLite'
import InsuranceOptions from './insuranceOptions/InsuranceOptions'
import LeadClusterInsurance from './leadCluster/Insurance/Insurance'
import LeadClustersSearch from './leadCluster/Search'
import LeadClusterShow from './leadCluster/Show'
import LeadSettings from './settings/leadSettings'
import ManufacturerModelSpecificationCategories from './manufacturers/ModelSpecificationCategories'
import ManufacturersWebsitesOverview from './manufacturers/Websites/Overview'
import ModelSpecifications from './models/ModelSpecifications'
import SearchManufacturers from './manufacturers/search'
import Orders from './orders/Orders'
import OrderStatusOptions from './orderStatusOptions/OrderStatusOptions'
import Plugins from './plugins'
import DeliveriesIndex from './deliveries/Index'
import SearchWebsitesReact from './websites/searchWebsitesReact'
import StockImageSetEditForm from './StockImageSets/EditForm'
import StockImageSetNewForm from './StockImageSets/NewForm'
import StockImageSetsSearch from './StockImageSets/Search'
import Layout from './entries/Layout'
import TestDrivesSearch from './testDrives/Search'
import TestDriveForm from './testDrives/TestDriveForm'
import UsersSearch from './users/Search'
import WebsitesMenuEditor from './websites/MenuEditor'
import WebsitesRedirects from './websites/Redirects'
import WebsitesSearchPages from './websites/SearchPages'
import WebsiteDeployments from './websites/WebsiteDeployments'
import Checkouts from './checkouts/Checkouts'

const bodyElement = document.getElementsByTagName('body')[0]
const ssoEnabled = bodyElement?.getAttribute('sso_enabled') === 'true'
const identityProvidersJson = bodyElement?.getAttribute('identity_providers') || '[]'
const isMobileClient = bodyElement?.getAttribute('is_mobile_client') === 'true'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Root route for error handling */}
      <Route
        path="/"
        element={
          <>
            <Outlet />
          </>
        }
        errorElement={<Error />}
      >
        <Route
          path="/"
          element={
            <IndexPage
              ssoEnabled={ssoEnabled}
              identityProvidersJson={identityProvidersJson}
              isMobileClient={isMobileClient}
            />
          }
        />
        <Route path="/admin/billing" element={<AdminBilling />} />
        <Route path="/admin/vehicle_detail_lookups" element={<AdminVehicleDetailLookups />} />
        <Route
          path="/admin/dealership_phone_calls_cost"
          element={<AdminDealershipPhoneCallsCost />}
        />
        <Route path="/carsales_accounts" element={<AdminCarsalesAccounts />} />
        <Route
          path="/social_ad_accounts/facebook"
          element={<AnalyticsSocialAdAccounts provider="Facebook" />}
        />
        <Route
          path="/social_ad_accounts/google"
          element={<AnalyticsSocialAdAccounts provider="Google" />}
        />
        <Route
          path="/dealerships/:dealershipSlug/websites/:websiteSlug"
          element={<AnalyticsDashboardApp />}
        />
        <Route
          path="/dealerships/:dealershipSlug"
          element={
            <Layout>
              <AnalyticsDashboardApp />
            </Layout>
          }
        />
        <Route path="/websites/:websiteSlug" element={<AnalyticsDashboardApp />} />
        <Route
          path="/dealerships/:dealershipSlug/appointments"
          element={
            <Layout>
              <AppointmentsCalendar />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/autograb"
          element={
            <Layout>
              <CarsAutograbIndex />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/reports"
          element={
            <Layout>
              <CarsReports />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/:carId/select_vehicle"
          element={
            <Layout>
              <CarsRedbook />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/new"
          element={
            <Layout>
              <CarsRedbook />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/contacts"
          element={
            <Layout>
              <ContactsSearch />
            </Layout>
          }
        />
        <Route path="/dealerships/:dealershipSlug/stock" element={<CarHealthAnalytics />} />
        <Route path="/manufacturers" element={<SearchManufacturers />} />
        <Route
          path="/dealership_groups/:dealershipGroupId/stock"
          element={<CarHealthAnalytics />}
        />
        <Route path="/dealerships/:dealershipSlug/cars/new" element={<CarsRedbook />} />
        <Route
          path="/dealerships/:dealershipSlug/cars/smart_publisher_settings"
          element={
            <Layout>
              <CarsSmartPublisherSettings />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars/stock_restrictions"
          element={
            <Layout>
              <CarsStockRestrictions />
            </Layout>
          }
        />
        <Route path="/dealerships/:dealershipSlug/contacts" element={<ContactsSearch />} />
        <Route
          path="/dealerships/:dealershipSlug/contacts/:contactId"
          element={
            <Layout>
              <ContactShow />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/dealership_users_unavailable_intervals"
          element={
            <Layout>
              <DealershipUsersUnavailableIntervals />
            </Layout>
          }
        />
        <Route path="/dealerships/:dealershipSlug/feeds" element={<FeedsIndex />} />
        <Route
          path="/dealerships/:dealershipSlug/facebook_ads"
          element={<AnalyticsSocialAdAccounts provider="Facebook" isDealership />}
        />
        <Route
          path="/dealerships/:dealershipSlug/google_ads"
          element={<AnalyticsSocialAdAccounts provider="Google" isDealership />}
        />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId/insurance"
          element={<LeadClusterInsurance />}
        />
        <Route path="/dealerships/:dealershipSlug/lead_settings" element={<LeadSettings />} />
        <Route
          path="/dealerships/:dealershipSlug/plugins"
          element={
            <Layout>
              <Plugins />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/pipelines/:pipelineId"
          element={<LeadClustersSearch />}
        />
        <Route
          path="/dealerships/:dealershipSlug/leads/kanban"
          element={
            <Layout>
              <LeadClustersSearch defaultLayout="kanban" />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/websites"
          element={
            <Layout>
              <SearchWebsitesReact />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/checkouts"
          element={
            <Layout>
              <Checkouts />
            </Layout>
          }
        />
        <Route
          path="/manufacturers/:manufacturerSlug/websites"
          element={
            <Layout>
              <SearchWebsitesReact />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/leads/kanban/pipelines/:pipelineId"
          element={
            <Layout>
              <LeadClustersSearch defaultLayout="kanban" />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/test_drives"
          element={
            <Layout>
              <TestDrivesSearch />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/test_drives/:testDriveId/edit"
          element={
            <TestDriveProvider>
              <TestDriveForm />
            </TestDriveProvider>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/test_drives/new"
          element={
            <Layout>
              <TestDriveProvider>
                <TestDriveForm />
              </TestDriveProvider>
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/car_loans"
          element={
            <Layout>
              <CarLoansSearch />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/car_loans/:carLoanId/edit"
          element={
            <CarLoanProvider>
              <CarLoanForm />
            </CarLoanProvider>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/car_loans/new"
          element={
            <Layout>
              <CarLoanProvider>
                <CarLoanForm />
              </CarLoanProvider>
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/users"
          element={
            <Layout>
              <UsersSearch />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/deliveries"
          element={
            <Layout>
              <DeliveriesIndex />
            </Layout>
          }
        />
        <Route path="/stock_image_sets" element={<StockImageSetsSearch />} />
        <Route path="/stock_image_sets/:id/edit" element={<StockImageSetEditForm />} />
        <Route path="/stock_image_sets/new" element={<StockImageSetNewForm />} />
        <Route path="/users" element={<UsersSearch />} />
        <Route path="/websites/:websiteSlug/menus" element={<WebsitesMenuEditor />} />
        <Route path="/websites/:websiteSlug/redirects" element={<WebsitesRedirects />} />
        <Route path="/websites/:websiteSlug/search_pages" element={<WebsitesSearchPages />} />
        <Route path="/websites/:websiteSlug/deployments" element={<WebsiteDeployments />} />
        <Route path="/admin/all_cars" element={<CarsSearch />} />
        <Route path="/admin/push_notifications" element={<AdminPushNotifications />} />
        <Route
          path="/cars/:carId"
          element={
            <Layout>
              <CarShow />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/cars"
          element={
            <Layout>
              <CarsSearch />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/facebook_catalogue"
          element={<DealershipsCatalogues />}
        />
        <Route path="/dealerships" element={<DealershipsIndex />} />
        <Route
          path="/dealerships/:dealershipSlug/cars/:carId/edit_redbook_details"
          element={<CarsEditRedbookDetails />}
        />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters"
          element={
            <Layout>
              <LeadClustersSearch />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId"
          element={
            <Layout>
              <LeadClusterShow />
            </Layout>
          }
        />
        <Route
          path="/dealerships/:dealershipSlug/lead_clusters/:leadClusterId/show_lite"
          element={<LeadClusterShowLite />}
        />
        <Route path="/websites/:websiteSlug/leads" element={<LeadClustersSearch />} />
        <Route path="/manufacturers/:manufacturerSlug" element={<AnalyticsDashboardApp />} />
        <Route path="/websites/:websiteSlug/orders" element={<Orders />} />
        <Route
          path="/websites/:websiteSlug/order_status_options"
          element={<OrderStatusOptions />}
        />
        <Route
          path="/manufacturers/:manufacturerSlug/model_specification_categories"
          element={<ManufacturerModelSpecificationCategories />}
        />
        <Route
          path="/manufacturers/:manufacturerSlug/websites/overview"
          element={<ManufacturersWebsitesOverview />}
        />
        <Route path="/models/:modelSlug/model_specifications" element={<ModelSpecifications />} />
        <Route path="/websites/:websiteSlug/insurance_options" element={<InsuranceOptions />} />
        <Route path="/websites/:websiteSlug/enquiry_addons" element={<EnquiryAddons />} />
      </Route>
    </>
  )
)

ReactDOM.createRoot(document.getElementById('app_root')).render(
  <RootContext.Provider value={{}}>
    <CurrentUserProvider>
      <DealershipProvider>
        <WebsiteProvider>
          <ManufacturerProvider>
            <RouterProvider router={router}>
              <ScrollRestoration />
            </RouterProvider>
          </ManufacturerProvider>
        </WebsiteProvider>
      </DealershipProvider>
    </CurrentUserProvider>
  </RootContext.Provider>
)
