import { format } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'

const FormattedDateTime = ({ timestamp }) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  // Convert UTC to the user's timezone
  const zonedDate = toZonedTime(new Date(timestamp), userTimezone)

  // Format date and time
  const formattedDate = format(zonedDate, 'dd/MM/yyyy')
  const formattedTime = format(zonedDate, 'hh:mm a')

  return (
    <div>
      <p>
        <span className="text-secondary">{formattedDate}</span>{' '}
        <span className="text-secondary">{formattedTime}</span>
      </p>
    </div>
  )
}

export default FormattedDateTime
